<template>
  <div class="container is-fullhd">
    <section class="hero is-white is-large">
      <div class="svganim">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="600.000000pt" height="600.000000pt" viewBox="0 0 600.000000 600.000000"
         preserveAspectRatio="xMidYMid meet">
        <metadata>
        Created by potrace 1.11, written by Peter Selinger 2001-2013
        </metadata>
        <g transform="translate(0.000000,550.000000) scale(1.20000,-1.20000)"
        fill="#000000" stroke="none">
            <path class="path" fill="#FFFFFF" stroke="#000000" stroke-width="0.4" stroke-miterlimit="1" d="M165.6,369.5c0,21.1,17.1,38.2,38.2,38.2s38.2-17.1,38.2-38.2c0-9.8-3.7-18.7-9.8-25.5h92.6c5.1,0,9.9-2,13.5-5.6
          		c3.6-3.6,5.6-8.4,5.6-13.5v-82.8h-12.7v82.8c0,1.7-0.7,3.3-1.9,4.5c-1.2,1.2-2.8,1.9-4.5,1.9h-121
          		C182.8,331.3,165.6,348.4,165.6,369.5z M178.4,369.5c0-14.1,11.4-25.5,25.5-25.5c14.1,0,25.5,11.4,25.5,25.5
          		c0,14.1-11.4,25.5-25.5,25.5C189.8,395,178.4,383.6,178.4,369.5z"/>
          	<path class="path" fill="#FFFFFF" stroke="#000000" stroke-width="0.4" stroke-miterlimit="1" d="M407.7,203.9c0-21.1-17.1-38.2-38.2-38.2c-9.8,0-18.7,3.7-25.5,9.8V82.8c0-5.1-2-9.9-5.6-13.5s-8.4-5.6-13.5-5.6h-82.8
          		v12.7h82.8c1.7,0,3.3,0.7,4.5,1.9c1.2,1.2,1.9,2.8,1.9,4.5v121c0,21.1,17.1,38.2,38.2,38.2C390.6,242.1,407.7,224.9,407.7,203.9z
          		 M395,203.9c0,14.1-11.4,25.5-25.5,25.5c-14.1,0-25.5-11.4-25.5-25.5c0-14.1,11.4-25.5,25.5-25.5C383.6,178.4,395,189.8,395,203.9z
          		"/>
          	<path class="path" fill="#FFFFFF" stroke="#000000" stroke-width="0.4" stroke-miterlimit="1" d="M242.1,38.2c0-21.1-17.1-38.2-38.2-38.2c-21.1,0-38.2,17.1-38.2,38.2c0,9.8,3.7,18.7,9.8,25.5H82.8c-5.1,0-9.9,2-13.5,5.6
          		s-5.6,8.4-5.6,13.5v82.8h12.7V82.8c0-1.7,0.7-3.3,1.9-4.5c1.2-1.2,2.8-1.9,4.5-1.9l121,0C224.9,76.4,242.1,59.3,242.1,38.2z
          		 M229.3,38.2c0,14.1-11.4,25.5-25.5,25.5c-14.1,0-25.5-11.4-25.5-25.5s11.4-25.5,25.5-25.5C217.9,12.7,229.3,24.2,229.3,38.2z"/>
          	<path class="path" fill="#FFFFFF" stroke="#000000" stroke-width="0.4" stroke-miterlimit="1" d="M38.2,165.6C17.1,165.6,0,182.8,0,203.9c0,21.1,17.1,38.2,38.2,38.2c9.8,0,18.7-3.7,25.5-9.8v92.6c0,5.1,2,9.9,5.6,13.5
          		c3.6,3.6,8.4,5.6,13.5,5.6h82.8v-12.7H82.8c-1.7,0-3.3-0.7-4.5-1.9c-1.2-1.2-1.9-2.8-1.9-4.5l0-121
          		C76.4,182.8,59.3,165.6,38.2,165.6z M63.7,203.9c0,14.1-11.4,25.5-25.5,25.5s-25.5-11.4-25.5-25.5c0-14.1,11.4-25.5,25.5-25.5
          		S63.7,189.8,63.7,203.9z"/>
          	<g>
          		<polygon class="path" fill="#FFFFFF" stroke="#000000" stroke-width="0.4" stroke-miterlimit="1" points="197.5,193.8 182.9,208.4 173.9,199.4 197.5,175.7 240.2,218.5 231.2,227.5 		"/>
          		<path class="path" fill="#FFFFFF" stroke="#000000" stroke-width="0.4" stroke-miterlimit="1" d="M305.8,305.8V101.9H101.9v203.9H305.8z M273.9,203.9c0,38.6-31.4,70.1-70.1,70.1c-38.6,0-70.1-31.4-70.1-70.1
          			c0-38.6,31.4-70.1,70.1-70.1C242.5,133.8,273.9,165.2,273.9,203.9z"/>
          	</g>
      </g>
        </svg>
      </div>
      <div class="svgnoanim">
        <img src="@/assets/projets.svg" alt="TheCompagnie | FPV | DRONE | MÉDIA" style="opacity:0.2;">
      </div>
      <div class="hero-body">
        <div class="container has-text-left">
          <h1 class="title">
            Web
          </h1>
          <h2 class="subtitle">
            Stratégie
          </h2>
        </div>
      </div>
    </section>
    <div class="container">
      <carousel :autoplay="true" :autoplayTimeout="5000" :perPage="carouseloptions.perpage">
        <slide v-for="(projet, key) in projets" :key="key">
          <div class="columns carousel">
            <div class="column texteprojet has-text-right" style="padding-right: 3em; padding-left: 3em;">
              <h3 class="subtitle client">{{projet.client}}</h3>
              <p v-html="projet.desc">
              </p>
            </div>
            <div class="column has-text-left imageprojet" @mouseout="projet.urlimage = projet.image" @mouseover="projet.urlimage = projet.image+'_hover'" :style="{ 'background-image': 'url('+projet.urlimage+'.jpg)' }">
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Projets',
  data() {
    return{
      carouseloptions: {
        perpage: 1
      },
      projets: [
        {
          client: "Formaât",
          image: "projets/formaat",
          urlimage: "projets/formaat",
          desc: "Mandataire formaliste, Formaât accompagne les entreprises dans leurs formalités juridiques sur toute la France.<br><br>TheCompagnie a développé l’outil de gestion clientèle de Formaât pour optimiser le processus de traitement des dossiers."
        },
        {
          client: "KeyCare",
          image: "projets/keycare",
          urlimage: "projets/keycare",
          desc: "Organisation de soins et d’aide à domicile à Genève, KeyCare offre des prestations individualisées, une relation de confiance pour une assistance au quotidien.<br><br>TheCompagnie est derrière la présence web de KeyCare, leur site web vitrine et leur référencement local. Nous sommes également en charge de leur communication sur support papier et impressions publicitaires."
        },
        {
          client: "FYN Surf",
          image: "projets/fynsurf",
          urlimage: "projets/fynsurf",
          desc: "Marque d’innovation dans le domaine du surf, FYN surf est à l’origine de la nouvelle technologie d’aileron dynamique pour les planches de surf.<br><br>TheCompagnie a réalisé la stratégie de communication et l’identité visuelle de la marque ainsi que la boutique en ligne, et d’autres applications web. Nous avons également assisté à la création et au design des produits lors des phases de conception."
        },
        {
          client: "Compagnie des propriétaires",
          image: "projets/cdp",
          urlimage: "projets/cdp",
          desc: "Agence de conciergerie au service des propriétaires, la Compagnie des propriétaires est établie depuis plus de 15ans sur les domaines des Arcs.<br><br>TheCompagnie a développé la plateforme de réservation de location d’appartement pour vacanciers. Nous sommes à l’origine du design de la plateforme de recherche web et de l’interface de consultation des appartements et de leur disponibilité."
        },
        {
          client: "KeyPlacement",
          image: "projets/keyplacement",
          urlimage: "projets/keyplacement",
          desc: "Délégation de personnel temporaire et fixe, KeyPlacement est une agence de placement à Genève.<br><br>TheCompagnie a conçu le site web de KeyPlacement et son interface d’offres d’emplois, ainsi que son interface de gestion interne des candidatures."
        },
        {
          client: "Ligue Française de Drone Freestyle",
          image: "projets/lfdf",
          urlimage: "projets/lfdf",
          desc: "Première ligue dédiée à la promotion des pilotes freestyle de drone FPV français. La LFDF offre une plateforme de compétition et une structure d’évolution pour ce hobby en pleine expansion.<br><br>TheCompagnie est à la genèse du projet et a participé avec passion au développement du concept et de la plateforme ainsi qu'à sa promotion. Nous avons également imaginé et conçu l’interface d’administration et de gestion du système compétitif."
        },
        {
          client: "Adelina Couture",
          image: "projets/adelinacouture",
          urlimage: "projets/adelinacouture",
          desc: "Atelier de couture et créations uniques fait main à Annecy. Adelina Couture est une entreprise locale à la créativité et au savoir-faire remarquable.<br><br>TheCompagnie a imaginé l’identité visuelle de l’atelier ainsi que sa stratégie de communication. Nous avons également conçu la petite boutique en ligne de la marque pour lui offrir une portée au-delà du local."
        },
        {
          client: "FRYS",
          image: "projets/frys",
          urlimage: "projets/frys",
          desc: "DJ animation de soirées et d’événements, FRYS est un duo de jeunes passionnés et ambitieux créateurs d’ambiances.<br><br>TheCompagnie est fier d’aider de jeunes entrepreneurs à obtenir une identité de communication professionnelle. Nous avons créé leur site vitrine et leur identité visuelle."
        },
        {
          client: "Assistance Secrétariat 74",
          image: "projets/as74",
          urlimage: "projets/as74",
          desc: "Services d’assistance administrative et de correction de documents, Assistance Secrétariat 74 fournissent des solutions clés en main pour vous simplifier le secrétariat.<br><br>TheCompagnie a mis en place le site web de cette nouvelle entreprise et a conçu son identité visuelle."
        }
      ]
    }
  },
  components: {
  },
}
</script>

<style lang="scss">
  .columns.carousel{
    margin-left:0;
    margin-right:0;
    padding-right: 3em;
    padding-left: 3em;
    padding-top: 80px;
  }
  .VueCarousel-pagination{
    position:absolute;
    .VueCarousel-dot-container{
      margin-top: 0 !important;
    }
  }

  @media screen and (max-width: 768px) {
    .imageprojet{
      height: 20em !important;
    }
    .columns.carousel{
      padding-right: 0em;
      padding-left: 0em;
    }
    .texteprojet{
      padding-left: 1em !important;
      padding-right: 1em !important;
    }
  }

  .imageprojet{
    background-position: center;
    background-size: cover;
    height: 45em;
    cursor: pointer;
    transition: 0.5s;
  }

  h3.subtitle.client{
    font-weight: 100;
  }
</style>
