<template>
  <div class="container is-fullhd">
    <section class="hero is-white is-large has-bg-img7 pagebg">
      <div class="hero-body" style="padding-top: 4em; padding-bottom: 50em;">
        <div class="container has-text-center">
          <div class="columns">
            <div class="column is-2"></div>
            <div class="column is-8 has-text-left" style="margin: 0 0 0 0; padding: 0 0 0 0;">
              <div class="video-container">
                <iframe
                      src="https://player.vimeo.com/video/678207380?background=1&autoplay=1&loop=1&byline=0&title=0"
                      frameborder="0"
                      width="100%"
                      allow="autoplay; fullscreen">
              </iframe>
              </div>
            </div>
            <div class="column is-2"></div>
          </div>
          <h2 class="title" style="font-size:1.5em;">
            <a href="mailto:contact@thecompagnie.eu">contact@thecompagnie.eu</a>
          </h2>
          <h2 class="subtitle" style="font-size:1.5em; margin-bottom: 0;">
            <a href="tel:+33677465434">+33 (0)6 77 46 54 34</a>
          </h2>
          <div style="margin-top: 0.7em; margin-bottom: 1em;">
            <a target="_blank" href="https://www.instagram.com/thecompagnie/">
              <img src="/instagram.svg" style="height:24px; margin-right: 1em;" alt="TheCompagnie | Instagram">
            </a>
            <a target="_blank" href="https://www.youtube.com/channel/UCbwOcqsQCYf8THFIbX3aqAw/">
              <img src="/youtube.svg" style="height:24px; margin-right: 1em;" alt="TheCompagnie | YouTube">
            </a>
            <a target="_blank" href="https://www.facebook.com/thecompagnie.eu/">
              <img src="/facebook.svg" style="height:24px;" alt="TheCompagnie | facebook">
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'Droneen',
  methods: {
    mailtocontact() {
      window.location.href = "mailto:contact@thecompagnie.eu"
    }
  },
  components: {
  }
}
</script>
<style lang="scss">
  .video-container {
  	position: relative;
  	padding-bottom: 56.25%;
  	padding-top: 30px;
  	height: 0;
  	overflow: hidden;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  }
  @media screen and (max-width: 768px) {
    .technos{
      img{
        padding-right: 1em !important;
        padding-left: 1em !important;
      }
    }
  }
  .technos{
    img{
      opacity: 0.1;
      min-height: 3em;
      max-height: 4em;
      padding-right: 3em;
      padding-left: 3em;
    }
  }
</style>
