<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <a target="_blank" href="https://www.instagram.com/thecompagnie/">
        <img src="/instagram.svg" style="height:24px; margin-right: 1em;" alt="TheCompagnie | Instagram">
      </a>
      <a target="_blank" href="https://www.youtube.com/channel/UCbwOcqsQCYf8THFIbX3aqAw/">
        <img src="/youtube.svg" style="height:24px;" alt="TheCompagnie | YouTube">
      </a>
      <p>
        <a href="https://www.thecompagnie.eu" target="_blank">The<strong>Compagnie</strong></a><br><a href="https://www.thecompagnie.eu/#/fpv">FPV</a> | <a href="https://www.thecompagnie.eu/#/drone">DRONE</a>  | <a href="https://www.thecompagnie.eu/#/services">MÉDIA</a><br>© 2018 - {{ new Date().getFullYear() }} tous droits réservés.
      </p>
      <router-link src="/logo_noir.svg" style="height:22px;" alt="TheCompagnie | FPV | DRONE | MÉDIA" tag="img" to="/">
      </router-link>
    </div>
  </footer>
</template>

<script>

export default {
  data() {
    return {
      toggleburger: false
    }
  }
}
</script>

<style lang='scss'>
  .footer{
    margin: 3em;
    border-top: 1px solid black;
    background-color: white !important;
    padding: 3em 0rem 0rem 0rem !important;
    margin-top: 3em !important;
  }

  @media screen and (max-width: 768px) {
    .footer{
      font-size: 0.6em;
    }
  }
</style>
