import Vue from 'vue';
import router from './router';
import App from './App.vue';
import Buefy from 'buefy';
import VueAnalytics from 'vue-analytics';
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import VueCarousel from 'vue-carousel';
import 'buefy/dist/buefy.css';

Vue.use(VueCarousel);

Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: 'UA-141604351-1',
  router
});

Vue.use(Buefy, {
  defaultIconPack: "fas"
});

// Création de l'instance Vue
new Vue({
  render: h => h(App),
  router,
  mounted() {
    // Déclenchement de l'événement 'render-event' après le montage de l'application
    document.dispatchEvent(new Event('render-event'));
  }
}).$mount('#app');
