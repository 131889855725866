<template>
  <section id="app">
    <NavBar/>
    <transition-page>
      <router-view></router-view>
    </transition-page>
    <Footer/>
  </section>
</template>

<script>
import NavBar from './components/elements/NavBar.vue'
import Footer from './components/elements/Footer.vue'
import TransitionPage from './components/TransitionPage.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Footer,
    TransitionPage,
  },
  mounted() {
    // Émettre l'événement pour le pré-rendu
    document.dispatchEvent(new Event('render-event'));
  },
};
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

@font-face {
  font-family: "Montserrat-Regular";
  src: url(~@/assets/font/montserrat/Montserrat-Regular.woff) format("woff");
  font-family: "Montserrat-Thin";
  src: url(~@/assets/font/montserrat/Montserrat-Thin.woff) format("woff");
  font-family: "Montserrat-ThinItalic";
  src: url(~@/assets/font/montserrat/Montserrat-ThinItalic.woff) format("woff");
  font-family: "Montserrat-SemiBold";
  src: url(~@/assets/font/montserrat/Montserrat-SemiBold.woff) format("woff");
  font-family: "Montserrat-SemiBoldItalic";
  src: url(~@/assets/font/montserrat/Montserrat-SemiBoldItalic.woff) format("woff");
  font-family: "Montserrat-Medium";
  src: url(~@/assets/font/montserrat/Montserrat-Medium.woff) format("woff");
  font-family: "Montserrat-MediumItalic";
  src: url(~@/assets/font/montserrat/Montserrat-MediumItalic.woff) format("woff");
  font-family: "Montserrat-Light";
  src: url(~@/assets/font/montserrat/Montserrat-Light.woff) format("woff");
  font-family: "Montserrat-LightItalic";
  src: url(~@/assets/font/montserrat/Montserrat-LightItalic.woff) format("woff");
  font-family: "Montserrat-Italic";
  src: url(~@/assets/font/montserrat/Montserrat-Italic.woff) format("woff");
  font-family: "Montserrat-ExtraLight";
  src: url(~@/assets/font/montserrat/Montserrat-ExtraLight.woff) format("woff");
  font-family: "Montserrat-ExtraLightItalic";
  src: url(~@/assets/font/montserrat/Montserrat-ExtraLightItalic.woff) format("woff");
  font-family: "Montserrat-ExtraBold";
  src: url(~@/assets/font/montserrat/Montserrat-ExtraBold.woff) format("woff");
  font-family: "Montserrat-ExtraBoldItalic";
  src: url(~@/assets/font/montserrat/Montserrat-ExtraBoldItalic.woff) format("woff");
  font-family: "Montserrat-Bold";
  src: url(~@/assets/font/montserrat/Montserrat-Bold.woff) format("woff");
  font-family: "Montserrat-BoldItalic";
  src: url(~@/assets/font/montserrat/Montserrat-BoldItalic.woff) format("woff");
  font-family: "Montserrat-Black";
  src: url(~@/assets/font/montserrat/Montserrat-Black.woff) format("woff");
  font-family: "Montserrat-BlackItalic";
  src: url(~@/assets/font/montserrat/Montserrat-BlackItalic.woff) format("woff");
}

@font-face {font-family: "AvertaStd-Regular";
  src: url("//db.onlinewebfonts.com/t/19b8f54eb1f074fb01efb1c4f02e9623.eot");
  src: url("//db.onlinewebfonts.com/t/19b8f54eb1f074fb01efb1c4f02e9623.eot?#iefix") format("embedded-opentype"),
  url("//db.onlinewebfonts.com/t/19b8f54eb1f074fb01efb1c4f02e9623.woff2") format("woff2"),
  url("//db.onlinewebfonts.com/t/19b8f54eb1f074fb01efb1c4f02e9623.woff") format("woff"),
  url("//db.onlinewebfonts.com/t/19b8f54eb1f074fb01efb1c4f02e9623.ttf") format("truetype"),
  url("//db.onlinewebfonts.com/t/19b8f54eb1f074fb01efb1c4f02e9623.svg#AvertaStd-Regular") format("svg");
}

@font-face {font-family: "AvertaStd-Extrathin"; src: url("//db.onlinewebfonts.com/t/82562c746c45bf39231fbf430dc5e0c2.eot"); src: url("//db.onlinewebfonts.com/t/82562c746c45bf39231fbf430dc5e0c2.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/82562c746c45bf39231fbf430dc5e0c2.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/82562c746c45bf39231fbf430dc5e0c2.woff") format("woff"), url("//db.onlinewebfonts.com/t/82562c746c45bf39231fbf430dc5e0c2.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/82562c746c45bf39231fbf430dc5e0c2.svg#AvertaStd-Extrathin") format("svg"); }

@font-face {font-family: "AvertaStd-Bold"; src: url("//db.onlinewebfonts.com/t/447315c0c330fd423c8e1cc18024669d.eot"); src: url("//db.onlinewebfonts.com/t/447315c0c330fd423c8e1cc18024669d.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/447315c0c330fd423c8e1cc18024669d.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/447315c0c330fd423c8e1cc18024669d.woff") format("woff"), url("//db.onlinewebfonts.com/t/447315c0c330fd423c8e1cc18024669d.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/447315c0c330fd423c8e1cc18024669d.svg#AvertaStd-Bold") format("svg"); }

// Set your colors
$primary: #000000;
$primary-invert: findColorInvert($primary);
$secondary: #e4bc63;
$secondary-invert: findColorInvert($secondary);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": ($white, $black),
  "black": ($black, $white),
  "light": ($light, $light-invert),
  "dark": ($dark, $dark-invert),
  "primary": ($primary, $primary-invert),
  "secondary": ($secondary, $secondary-invert),
  "info": ($info, $info-invert),
  "success": ($success, $success-invert),
  "warning": ($warning, $warning-invert),
  "danger": ($danger, $danger-invert),
  "twitter": ($twitter, $twitter-invert)
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

@media only screen and (min-width: 600px) {
  .is-vertical-center {
    display: flex;
    align-items: center;
  }
}

#app {
  font-family: 'Montserrat-Regular', 'AvertaStd-Regular', Helvetica, Arial, sans-serif;
  font-size: 0.9em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: calc(4em + 52px);
  @media screen and (max-width: 768px) {
    margin-top: calc(52px);
  }
}

a{
  color: black !important;
}

h1.title{
  font-size: 2em;
  text-transform: uppercase;
  font-family: 'Montserrat-Bold', 'AvertaStd-Regular', Helvetica, Arial, sans-serif !important;
}
h2.subtitle{
  font-size: 2em;
  text-transform: uppercase;
  font-family: 'Montserrat-ExtraLight', 'AvertaStd-Regular', Helvetica, Arial, sans-serif !important;
}
h3.subtitle{
  font-size: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  color: black;
}
h4.subtitle{
  font-size: 0.9em;
  font-weight: 700;
  text-transform: uppercase;
  color: black;
  margin-top: 1.5em !important;
  margin-bottom: 0rem !important;
}

.svgnoanim {
  display:none;
}

.special{
  font-size: 3em;
}

@media screen and (max-width: 768px) {
  .hero{
    .special{
      font-size: 2em;
    }
    h1.title{
      font-size: 1.2em;
    }
    h2.subtitle{
      font-size: 1.2em;
    }
  }
  h3.subtitle{
    font-size: 1em;
  }
  h4.subtitle{
    font-size: 0.8em;
  }
  .svganim {
    display:none;
  }
  .svgnoanim {
    display:unset;
    right: 0px;
    top: 0.2em;
    position: absolute;
    max-height: 120px;
    padding-right: 1em;
    img{
      height: 10em;
    }
  }
  .navbar{
    padding-top: 0em;
    padding-bottom: 0em;
    padding-left: 0em;
    padding-right: 0em;
    .navbar-menu{
      box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  .columns{
    margin-left: 0;
    margin-right: 0;
    .column.has-text-right{
      border-left: none !important;
      padding-right: 1em !important;
      padding-left: 1em !important;
      margin-bottom: 1em !important;
    }
    .column.has-text-left{
      border-right: none !important;
      padding-left: 1em !important;
      padding-right: 1em !important;
      margin-bottom: 1em !important;
    }
    .column.has-text-justified{
      border-left: none !important;
      border-right: none !important;
      padding-right: 1em !important;
      padding-left: 1em !important;
      margin-bottom: 1em !important;
    }
    .column.has-text-center{
      border-left: none !important;
      border-right: none !important;
      padding-right: 1em !important;
      padding-left: 1em !important;
      margin-bottom: 1em !important;
    }
  }
}

ul{
  li{
    color: gray;
  }
}

.svganim{
  right: -8em;
  top: -8em;
  position: absolute;

  .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear alternate infinite;
  }

  @keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
  }
}
.has-bg-img1 {
  background: url('~/voituredroneback.jpg')center center;
  background-size:cover;
  &.pagebg{
    @media screen and (min-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 30%), url('~/voituredroneback.jpg');
    }
    @media screen and (max-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 0%), url('~/voituredroneback.jpg');
    }
  }
}
.has-bg-img2 {
  background: url('~/audininesdrone.jpg')center center;
  background-size:cover;
  &.pagebg{
    @media screen and (min-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 30%), url('~/audininesdrone.jpg');
    }
    @media screen and (max-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 0%), url('~/audininesdrone.jpg');
    }
  }
}
.has-bg-img3 {
  background: url('~/visitedroneback.jpg')center center;
  background-size:cover;
  &.pagebg{
    @media screen and (min-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 30%), url('~/visitedroneback.jpg');
    }
    @media screen and (max-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 0%), url('~/visitedroneback.jpg');
    }
  }
}
.has-bg-img4 {
  background: url('~/speedflyingdroneback.jpg')center center;
  background-size:cover;
}
.has-bg-img5 {
  background: url('~/dronepiloteback.jpg')center center;
  background-size:cover;
  &.pagebg{
    @media screen and (min-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 30%), url('~/dronepiloteback.jpg');
    }
    @media screen and (max-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 0%), url('~/dronepiloteback.jpg');
    }
  }
}
.has-bg-img6 {
  background: url('~/servicesdroneback.jpg')center center;
  background-size:cover;
  &.pagebg{
    @media screen and (min-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 30%), url('~/servicesdroneback.jpg');
    }
    @media screen and (max-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 0%), url('~/servicesdroneback.jpg');
    }
  }
}
.has-bg-img7 {
  background: url('~/cardsbackground.png')center center;
  background-size:cover;
  &.pagebg{
    @media screen and (min-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 30%), url('~/cardsbackground.png');
    }
    @media screen and (max-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 0%), url('~/cardsbackground.png');
    }
  }
}
.has-bg-img8 {
  background: url('~/parapentedrone.jpg')center center;
  background-size:cover;
  &.pagebg{
    @media screen and (min-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 30%), url('~/parapentedrone.jpg');
    }
    @media screen and (max-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 0%), url('~/parapentedrone.jpg');
    }
  }
}
.has-bg-img9 {
  background: url('~/cinemafpv.jpg')center center;
  background-size:cover;
  &.pagebg{
    @media screen and (min-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 30%), url('~/cinemafpv.jpg');
    }
    @media screen and (max-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 0%), url('~/cinemafpv.jpg');
    }
  }
}
.has-bg-img10 {
  background: url('~/cinemafpv2.jpg')center center;
  background-size:cover;
  &.pagebg{
    @media screen and (min-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 30%), url('~/cinemafpv2.jpg');
    }
    @media screen and (max-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 0%), url('~/cinemafpv2.jpg');
    }
  }
}
.has-bg-img11 {
  background: url('~/fondkomodo.jpg')center center;
  background-size:cover;
  &.pagebg{
    @media screen and (min-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 30%), url('~/fondkomodo.jpg');
    }
    @media screen and (max-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 0%), url('~/fondkomodo.jpg');
    }
  }
}
.has-bg-img12 {
  background: url('~/fondbgh1.jpg')center center;
  background-size:cover;
  &.pagebg{
    @media screen and (min-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 30%), url('~/fondbgh1.jpg');
    }
    @media screen and (max-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 0%), url('~/fondbgh1.jpg');
    }
  }
}
.has-bg-img13 {
  background: url('~/livedrone.png')center center;
  background-size:cover;
  &.pagebg{
    @media screen and (min-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 30%), url('~/livedrone.png');
    }
    @media screen and (max-width: 768px){
      background-image: linear-gradient(#ffffff 0%, transparent 0%), url('~/livedrone.png');
    }
  }
}


h2.subtitle.smallsub{
  font-size: 2em
}
h2.subtitle.smallline{
  font-size: 1.75em;
  padding-left: 1em;
}
@media screen and (max-width: 768px){
.hero h2.subtitle {
    font-size: 0.8em !important;
}
}

.hero{
  .title{
    font-size: 3em;
    font-family: 'Montserrat-Bold', 'AvertaStd-Bold';
  }
  .subtitle{
    font-size: 3em;
    font-family: 'Montserrat-Regular', 'AvertaStd-Regular';
  }
}

iframe.bg-video {
 width: 100vw;
 height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
 min-height: 50vh;
 min-width: 177.77vh;
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
  z-index: 0;
}
</style>
