<template>
  <div class="container is-fullhd">
    <section class="hero is-black is-large has-bg-img8 pagebg">
      <div class="hero-body">
        <div class="svganim">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="600.000000pt" height="600.000000pt" viewBox="0 0 600.000000 600.000000"
           preserveAspectRatio="xMidYMid meet">
          <metadata>
          Created by potrace 1.11, written by Peter Selinger 2001-2013
          </metadata>
          <g transform="translate(50.000000,500.000000) scale(5.50000,-5.50000)"
          fill="#000000" stroke="none">
          <path class="path" fill="transparent" stroke="#FFFFFF" stroke-width="0.16" stroke-miterlimit="1" d="M44.7,49.4c0-0.7-0.6-1.4-1.3-1.4c-0.8,0-1.4,0.6-1.4,1.3c0,0.8,0.6,1.4,1.3,1.4C44.1,50.7,44.7,50.1,44.7,49.4
          	z M43,56.1c-1.3,0-2.5-0.4-3.5-1.1c0.2-0.2,0.3-0.4,0.4-0.5c0.9,0.6,2,0.9,3.1,0.9c1.4,0,2.7-0.4,3.8-1.2c0.2,0.2,0.3,0.3,0.5,0.5
          	C46.1,55.6,44.6,56.1,43,56.1z M43.7,51.9l0.4,2.4c0,0,0,0,0,0c-0.6,0.1-1.3,0.1-1.9,0l0.5-2.4c0.2,0,0.3,0.1,0.5,0.1
          	C43.3,52,43.5,51.9,43.7,51.9z M45.2,52.8c0.3,0.2,0.6,0.4,0.9,0.7c-0.1,0.1-0.3,0.2-0.4,0.3L45.2,52.8z M50.2,49.3
          	C50.2,49.3,50.2,49.3,50.2,49.3c-0.2,0-0.5,0-0.7,0c0.1-2.6-1.5-4.8-3.7-5.8c0.1-0.2,0.1-0.4,0.2-0.7C48.5,43.8,50.2,46.4,50.2,49.3
          	z M45.8,48c-0.1-0.3-0.3-0.6-0.5-0.8l0,0l1.7-1.6c0,0,0,0,0,0c0.4,0.5,0.8,1,1,1.6L45.8,48z M45.4,44.9c0-0.2,0.1-0.3,0.1-0.5
          	c0.1,0,0.1,0,0.2,0.1L45.4,44.9z M36.2,48.9c0.1-2.6,1.6-4.8,3.7-6c0.1,0.2,0.2,0.4,0.2,0.6c-1.9,1-3.2,3-3.3,5.3c0,0.3,0,0.5,0,0.8
          	c-0.2,0-0.4,0.1-0.7,0.1C36.2,49.6,36.2,49.2,36.2,48.9z M40.5,44.6c0,0.1,0.1,0.3,0.1,0.4L40.5,44.6C40.4,44.7,40.4,44.7,40.5,44.6
          	C40.4,44.6,40.5,44.6,40.5,44.6z M39.1,45.8l1.9,1.5c-0.2,0.3-0.4,0.6-0.5,0.9l-2.3-0.8c0,0,0,0,0,0C38.4,46.8,38.7,46.3,39.1,45.8z
          	 M38.8,49.1c-0.3,0.1-0.5,0.2-0.8,0.3c0-0.1,0-0.2,0-0.3L38.8,49.1z M0.5,72.6c0,0,27.3-19,28.5-19.9c4.1-3.1,5-2.2,6.3-2.3
          	c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0,0.6-0.1,0.9-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0.6-0.1,1.2-0.3,1.7-0.4l0,0c1-0.3,1.8-0.8,2.8-1.3
          	c0.1-0.4,0.3-0.8,0.7-1.1c0-0.2,0-0.5-0.1-0.7c0-0.2-0.1-0.4-0.1-0.6c0,0,0,0,0,0v0c-0.2-1.1-0.6-2.2-1.1-3.5
          	c-0.1-0.2-0.2-0.4-0.2-0.7c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c-0.7-2-1.9-3.9-2.4-5.3c-0.4-1.2-0.5-3.2-0.5-4.7c0-0.4,0-0.8,0.1-1.1
          	c0.2-2.9,0.8-5.8,1.1-8.5c0.3-2.7,0.7-5.2,1-7.6c0.3-2.3,0.7-4.5,1-6.4c0.3-1.9,0.7-3.6,1-4.9C41.7,0.5,44,0.4,44,0.4
          	s2.9,33.1,3.1,34.6c0.1,1.2,0.2,2.1,0.2,2.8c-0.1,2.5-0.9,2.9-1.3,3.9c-0.7,1.6-0.9,2.8-1,4.1v0c0,0,0,0,0,0c0,0.3,0,0.6,0,0.9
          	c0,0.2,0,0.4,0,0.6c0,0,0,0.1,0.1,0.1l0,0c0.3,0.3,0.5,0.7,0.6,1.1c0.3,0.1,0.6,0.3,0.9,0.4c0.1,0,0.1,0,0.2,0.1
          	c0.6,0.2,1.1,0.4,1.7,0.5v0c0,0,0,0,0.1,0c0.3,0.1,0.6,0.1,1,0.2c0,0,0,0,0,0c0.2,0,0.4,0.1,0.7,0.1c0.3,0,0.6,0.1,0.9,0.2
          	c0.1,0,0.2,0,0.2,0c2.1,0.4,4.3,0.3,5.8,0.5c1.6,0.3,4.1,1.7,5.3,2.6c2.4,1.7,4.6,3.6,6.8,5.2c2.2,1.6,4.2,3.2,6,4.7
          	c1.9,1.5,3.5,2.8,5,4.1c1.5,1.2,2.7,2.4,3.8,3.4c1,0.9,1.3,1.9,1.3,2.7c0,0.7-0.3,1.2-0.3,1.2S54.8,60.2,53.4,59.6
          	c-4.7-2-4.4-3.2-5.2-4.3c-0.4-0.5-0.7-0.9-1-1.3h0c0,0,0,0,0,0c-0.2-0.3-0.5-0.5-0.7-0.7c0,0,0,0,0,0c-0.5-0.4-1-0.8-1.6-1.2l0,0
          	c0,0,0,0-0.1,0c-0.3-0.2-0.5-0.3-0.8-0.5c-0.1,0-0.2,0-0.3,0.1c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.1,0-0.2,0
          	c-0.2,0.2-0.5,0.3-0.7,0.5c0,0,0,0,0,0c-0.6,0.5-1.1,0.9-1.5,1.5l0,0c0,0,0,0,0,0c-0.4,0.4-0.7,0.8-1.1,1.3h0
          	c-0.2,0.2-0.4,0.5-0.6,0.7h0c0,0.1-0.1,0.1-0.1,0.2c-1.3,1.6-2.4,3.6-3.4,4.7c-1,1.2-3.5,2.7-4.9,3.3c-2.7,1.2-5.4,2.2-7.9,3.3
          	c-2.5,1.1-4.9,2-7.1,2.9c-2.2,0.9-4.2,1.6-6.1,2.3c-1.8,0.7-3.5,1.1-4.8,1.6C1.7,74.6,0.5,72.6,0.5,72.6z"/>
          </g>
          </svg>
        </div>
        <div class="svgnoanim">
          <img src="@/assets/drone.svg" alt="TheCompagnie |FPV | Drone | Média" style="opacity:1 !important;">
        </div>
        <div class="container has-text-left">
          <h1 class="title">
            Professional pilots
          </h1>
          <h2 class="subtitle">
            Unique images
          </h2>
        </div>
      </div>
    </section>
    <div class="container" style="padding-top: 4em;">
      <div class="columns is-multiline">
        <div class="column is-12 has-text-justified" style="padding-right: 3em; padding-left: 3em; margin-bottom: 3em;">
          <p>
            Drone pilots<strong>approved by French directives (DGAC)</strong> we offer our services of <strong>professional drone pilots</strong>. We are trained to ensure and execute our missions in complete safety and in compliance with the rules initiated by local governments.
          </p>
          <br>
          <p>
            We pilot <strong>professional flight machines</strong> known for their <strong>reliability</strong> and their <strong>effectivness</strong>. And also quadcopters developed by us with exceptionel <strong>speed</strong> and <strong>handling</strong> (speed of 220km/h | 130mph, from 0 to 130km/h | 80mph in 1 second).
          </p>
        </div>
        <div class="column is-7 has-text-left" style="padding-right: 1em; padding-left: 3em; margin-bottom: 3em;">
          <h3 class="subtitle">
            Aerial shot
          </h3>
          <p>
            Our expertise in flight physiognomy and our knowledge of on-board cinematographic tools allow us to offer you perfect productions in a multitude of cases.
          </p><br>
          <div class="columns">
            <div class="column is-6">
              <ul>
                  <li><strong>Characteristics</strong></li>
      						<li>Security</li>
      						<li>Autonomy</li>
                  <li>Stability</li>
      						<li>Precision</li>
      					</ul>
              </div>
              <div class="column is-6">
                <ul>
                  <li><strong>Use case</strong></li>
      						<li>Video</li>
      						<li>Photography</li>
      						<li>Photogrammetry</li>
                  <li>Topography</li>
      					</ul>
              </div>
            </div>
        </div>
        <div class="column is-5 has-text-left" style="margin-bottom: 3em;">
          <div class="video-container">
            <iframe src="https://player.vimeo.com/video/678222658" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>
        <div class="column is-5 has-text-left">
          <div class="video-container">
            <iframe src="https://player.vimeo.com/video/657380025" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>
        <div class="column is-7 has-text-left" style="padding-right: 3em; padding-left: 1em; margin-bottom: 3em;">
          <h3 class="subtitle">
            Dynamic shooting (FPV)
          </h3>
          <p>
            Our expertise in the assembly and piloting of aircraft in immersive view allows us to obtain exceptional flight characteristics, offering a unique point of view and dynamic movement.
            Performances of our machines offers unprecedented possibilities.
          </p><br>
          <div class="columns">
            <div class="column is-6">
              <ul>
                <li><strong>Characteristics</strong></li>
                <li>Speed <small>(+220km/h | +130mph)</small></li>
                <li>Acceleration <small>(0 to 130km/h | 80mph in 1s)</small></li>
                <li>Maneuverability</li>
                <li>Solidity<br><small>(design | repair by us)</small></li>
    					</ul>
            </div>
            <div class="column is-6">
              <ul>
                <li><strong>Use case</strong></li>
                <li>High speed tracking</li>
                <li>Sports video</li>
                <li>Virtual tours</li>
    						<li>Restricted spaces</li>
    					</ul>
            </div>
          </div>
        </div>
        <div class="column is-12 has-text-center" style="display:none; padding-right: 3em; padding-left: 3em; margin-bottom: 3em;">
          <h3 class="subtitle">
            Accompagnement et formation drone et FPV (vol en immersion)
          </h3>
          <p>
            Nous dispensons également des formations pratiques au pilotage et un soutien à votre développement en tant que télépilote (amateur ou professionnel). Nos télépilotes certifiés par la DGAC sont en mesure de délivrer l'attestation de suivi de formation indispensable à la professionnalisation de télépilote drone.
          </p><br>
          <div class="columns">
            <div class="column is-6">
              <ul>
                  <li><strong>Formation pratique</strong></li>
      						<li>Prise en main des aéronefs (FPV)</li>
      						<li>Mise en sécurité du vol</li>
                  <li>Optimisation des prestations télépilote</li>
      						<li>Perfectionnement au pilotage (FPV)</li>
      					</ul>
              </div>
              <div class="column is-6">
                <ul>
                  <li><strong>Accompagnement</strong></li>
      						<li>Montage et réparation des machines (FPV)</li>
      						<li>Réglage des drones et outils de capture vidéo</li>
                  <li>Traitement et montage vidéo des captures aériennes</li>
      						<li>Soutien au développement de l’activité</li>
      					</ul>
              </div>
            </div>
        </div>

        <div class="column is-12 has-text-center" style="display:none;">
          <p>
            Nos pilotes professionnels sont en mesure de vous fournir une formation et un soutien à votre progression et votre perfectionnement.
          </p>
        </div>

        <div class="column is-12 has-text-center">
          <p>
            <h4 class="subtitle">For more information or any request for production:</h4><br/>
            <b-button
              size="is-medium"
              icon-left="envelope"
              @click="mailtocontact"
              style="border: 1px solid black;"
              outlined>
              Contact us
            </b-button>
          </p>
        </div>

        <div class="column is-12 has-text-center" style="display:none;">
          <p>
            <h4 class="subtitle">Pour plus d’informations ou toute demande de formation ou de soutien, n’hésitez pas :</h4><br/>
            <b-button
              size="is-medium"
              icon-left="envelope"
              @click="mailtocontact"
              style="border: 1px solid black;"
              outlined>
              Contactez-nous
            </b-button>
          </p>
        </div>
      </div>
    </div>
    <div class="container" style="margin-top: 3em;">
      <div class="columns is-mobile is-multiline technos">

        <div class="column is-12 has-text-center" style="display:none;">
          <p>
            Dans le cadre de votre formation théorique télépilote nous vous recommandons de vous préparer sur le site : <a href="https://www.drone-exam.fr" target="_blank">www.drone-exam.fr</a>
          </p>
        </div>
        <div class="column is-12 has-text-center" style="padding-right: 3em; padding-left: 3em; margin-bottom: 3em;">
          <p>
            We work with professional equipment known and recognized for its reliability and efficiency.
          </p>
        </div>
        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/dji.svg" title="DJI" alt="TheCompagnie DJI" width="100%">
        </div>
        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/reddigitalcinema.svg" title="RED Digital Cinema" alt="TheCompagnie RED Digital Cinema" width="100%">
        </div>
        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/tbs.svg" title="T-Motor" alt="TheCompagnie T-Motor" width="100%">
        </div>
        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/lumix.svg" title="Lumix" alt="TheCompagnie Lumix" width="100%">
        </div>
        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/frsky.svg" title="FrSKY" alt="TheCompagnie FrSKY" width="100%">
        </div>
        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/gopro.svg" title="GoPro" alt="TheCompagnie GoPro" width="100%">
        </div>
        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/atomos.svg" title="Atomos" alt="TheCompagnie Atomos" width="100%">
        </div>
        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/truerccanada.svg" title="TrueRC Canada" alt="TheCompagnie TrueRC Canada" width="100%">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Drone',
  methods: {
    mailtocontact() {
      window.location.href = "mailto:contact@thecompagnie.eu"
    }
  },
  components: {
  }
}
</script>
<style lang="scss">
  .video-container {
  	position: relative;
  	padding-bottom: 56.25%;
  	padding-top: 30px;
  	height: 0;
  	overflow: hidden;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  }
  @media screen and (max-width: 768px) {
    .technos{
      img{
        padding-right: 1em !important;
        padding-left: 1em !important;
      }
    }
  }
  .technos{
    img{
      opacity: 0.1;
      min-height: 3em;
      max-height: 4em;
      padding-right: 3em;
      padding-left: 3em;
    }
  }
</style>
