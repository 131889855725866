<template>
  <div class="container is-fullhd">
    <section class="hero is-black is-large has-bg-img6 pagebg">
      <div class="hero-body">
        <div class="svganim">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="600.000000pt" height="600.000000pt" viewBox="0 0 600.000000 600.000000"
           preserveAspectRatio="xMidYMid meet">
          <metadata>
          Created by potrace 1.11, written by Peter Selinger 2001-2013
          </metadata>
          <g transform="translate(100.000000,500.000000) scale(1.30000,-1.30000)"
          fill="#000000" stroke="none">
          <path class="path" fill="transparent" stroke="#ffffff" stroke-width="0.4" stroke-miterlimit="1" d="M258.4,138.1H291c2,10.2,10.9,17.9,21.7,17.9c12.2,0,22.1-9.9,22.1-22.1s-9.9-22.1-22.1-22.1c-10.7,0-19.7,7.7-21.7,17.9
          	h-32.6V80.5c0-2.3-1.9-4.2-4.2-4.2h-4.8V65.2c0-2.3-1.9-4.2-4.2-4.2s-4.2,1.9-4.2,4.2v11.2h-14V65.2c0-2.3-1.9-4.2-4.2-4.2
          	s-4.2,1.9-4.2,4.2v11.2H205V55.6c10.2-2,17.9-10.9,17.9-21.7c0-12.2-9.9-22.1-22.1-22.1s-22.1,9.9-22.1,22.1
          	c0,10.7,7.7,19.7,17.9,21.7v20.8h-13.9V65.2c0-2.3-1.9-4.2-4.2-4.2c-2.3,0-4.2,1.9-4.2,4.2v11.2h-13.9V65.2c0-2.3-1.9-4.2-4.2-4.2
          	c-2.3,0-4.2,1.9-4.2,4.2v11.2h-13.9V43.8c10.2-2,17.9-10.9,17.9-21.7C156,9.9,146.1,0,133.9,0s-22.1,9.9-22.1,22.1
          	c0,10.7,7.7,19.7,17.9,21.7v32.6h-14V65.2c0-2.3-1.9-4.2-4.2-4.2s-4.2,1.9-4.2,4.2v11.2h-14V65.2c0-2.3-1.9-4.2-4.2-4.2
          	s-4.2,1.9-4.2,4.2v11.2h-4.5c-2.3,0-4.2,1.9-4.2,4.2v49.2H43.8c-2-10.2-10.9-17.9-21.7-17.9C9.9,111.8,0,121.7,0,133.9
          	S9.9,156,22.1,156c10.7,0,19.7-7.7,21.7-17.9h32.6v58.5H43.8c-2-10.2-10.9-17.9-21.7-17.9C9.9,178.7,0,188.6,0,200.8
          	s9.9,22.1,22.1,22.1c10.7,0,19.7-7.7,21.7-17.9h32.6v49.2c0,2.3,1.9,4.2,4.2,4.2h4.8v11.2c0,2.3,1.9,4.2,4.2,4.2s4.2-1.9,4.2-4.2
          	v-11.2h14v11.2c0,2.3,1.9,4.2,4.2,4.2s4.2-1.9,4.2-4.2v-11.2h13.7v20.8c-10.2,2-17.9,10.9-17.9,21.7c0,12.2,9.9,22.1,22.1,22.1
          	S156,313,156,300.8c0-10.7-7.7-19.7-17.9-21.7v-20.8H152v11.2c0,2.3,1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2v-11.2h13.9v11.2
          	c0,2.3,1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2v-11.2h13.9V291c-10.2,2-17.9,10.9-17.9,21.7c0,12.2,9.9,22.1,22.1,22.1
          	s22.1-9.9,22.1-22.1c0-10.7-7.7-19.7-17.9-21.7v-32.6h14v11.2c0,2.3,1.9,4.2,4.2,4.2s4.2-1.9,4.2-4.2v-11.2h14v11.2
          	c0,2.3,1.9,4.2,4.2,4.2s4.2-1.9,4.2-4.2v-11.2h4.5c2.3,0,4.2-1.9,4.2-4.2V205H291c2,10.2,10.9,17.9,21.7,17.9
          	c12.2,0,22.1-9.9,22.1-22.1s-9.9-22.1-22.1-22.1c-10.7,0-19.7,7.7-21.7,17.9h-32.6V138.1z M312.6,120.2c7.6,0,13.7,6.2,13.7,13.7
          	c0,7.6-6.2,13.7-13.7,13.7c-7.6,0-13.7-6.2-13.7-13.7C298.9,126.4,305.1,120.2,312.6,120.2z M187.1,33.9c0-7.6,6.2-13.7,13.7-13.7
          	c7.6,0,13.7,6.2,13.7,13.7c0,7.6-6.2,13.7-13.7,13.7C193.2,47.6,187.1,41.5,187.1,33.9z M120.2,22.1c0-7.6,6.2-13.7,13.7-13.7
          	c7.6,0,13.7,6.2,13.7,13.7c0,7.6-6.2,13.7-13.7,13.7C126.4,35.8,120.2,29.7,120.2,22.1z M22.1,147.7c-7.6,0-13.7-6.2-13.7-13.7
          	c0-7.6,6.2-13.7,13.7-13.7c7.6,0,13.7,6.2,13.7,13.7C35.8,141.5,29.7,147.7,22.1,147.7z M22.1,214.5c-7.6,0-13.7-6.2-13.7-13.7
          	c0-7.6,6.2-13.7,13.7-13.7c7.6,0,13.7,6.2,13.7,13.7C35.8,208.4,29.7,214.5,22.1,214.5z M147.7,300.8c0,7.6-6.2,13.7-13.7,13.7
          	c-7.6,0-13.7-6.2-13.7-13.7c0-7.6,6.2-13.7,13.7-13.7C141.5,287.1,147.7,293.3,147.7,300.8z M214.5,312.6c0,7.6-6.2,13.7-13.7,13.7
          	c-7.6,0-13.7-6.2-13.7-13.7c0-7.6,6.2-13.7,13.7-13.7C208.4,298.9,214.5,305.1,214.5,312.6z M312.6,187.1c7.6,0,13.7,6.2,13.7,13.7
          	c0,7.6-6.2,13.7-13.7,13.7c-7.6,0-13.7-6.2-13.7-13.7C298.9,193.2,305.1,187.1,312.6,187.1z M250,250H84.7V84.7H250V250z"/>
          <path class="path" fill="transparent" stroke="#ffffff" stroke-width="0.4" stroke-miterlimit="1" d="M118.2,220.7h98.4c2.3,0,4.2-1.9,4.2-4.2v-98.4c0-2.3-1.9-4.2-4.2-4.2h-98.4c-2.3,0-4.2,1.9-4.2,4.2v98.4
          	C114,218.8,115.9,220.7,118.2,220.7z M122.4,122.4h90v90h-90V122.4z"/>
          </g>
          </svg>
        </div>
        <div class="svgnoanim">
          <img src="@/assets/services.svg" alt="Services de drone FPV - The Compagnie" style="opacity:1;">
        </div>
        <div class="container has-text-left">
          <h1 class="title">Services</h1>
          <h2 class="subtitle">Technologies</h2>
        </div>
      </div>
    </section>

    <div class="container" style="padding-top: 4em;">
      <div class="columns is-multiline">
        <div class="column is-12 has-text-center" style="padding-right: 3em; padding-left: 3em; margin-bottom: 3em;">
          <p>
            <b>TheCompagnie développe des services uniques intimement liés à l'évolution des technologies</b> et des nouvelles possibilités qu'apportent les drones et le FPV.
          </p>
          <p>
            Nous concevons de nouveaux outils pour vous offrir des services innovants pour votre développement et votre communication.
          </p>
        </div>
        <div class="column is-12 has-text-center" style="padding-right: 3em; padding-left: 3em; margin-bottom: 3em;">
          <p>
            L'univers du drone et du FPV offre un champ des possibles inédit! Mais <b>il est essentiel pour nous de privilégier des bases solides</b> à son expansion, nous mettons un point d'honneur à ne proposer que des <b>processus de création stables et performants</b>.
          </p>
        </div>

        <div class="column has-text-left" style="padding-right: 3em; padding-left: 3em;">
          <h3 class="subtitle">Nos services</h3>
          <ul>
            <li>Vidéo en drone FPV</li>
            <li>Diffusion en direct FPV</li>
            <li>Suivis dynamique en drone</li>
            <li>Visites virtuelles en drone intérieur</li>
            <li>Visites virtuelles FPV extérieur</li>
            <li>Poursuite à grande vitesse en drone FPV</li>
            <li>Drone FPV cinématique</li>
          </ul>
        </div>

        <div class="column has-text-left" style="border-left: 1px solid lightgray; padding-right: 3em; padding-left: 3em;">
          <h3 class="subtitle">Nos engagements</h3>
          <ul>
            <li>Sécurité de la prestation</li>
            <li>Connaissance et respect des législations</li>
            <li>Des pilotes experts et créatifs</li>
            <li>Performance et innovation</li>
            <li>Tarifs concurrentiels</li>
            <li>Vitesse de production</li>
            <li>Couverture nationale et internationale</li>
          </ul>
        </div>

        <div class="column has-text-left" style="border-left: 1px solid lightgray; padding-right: 3em; padding-left: 3em;">
          <h3 class="subtitle">Nos compléments</h3>
          <ul>
            <li>Montage vidéo</li>
            <li>Sound design</li>
            <li>Création musicale</li>
            <li>Intégration web</li>
            <li>Démarches administratives</li>
            <li>Conception de drones</li>
            <li>Télépilotes</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container" style="margin-top: 5em;">
      <div class="columns is-mobile is-multiline technos">
        <div class="column is-12 has-text-center" style="padding-right: 3em; padding-left: 3em; margin-bottom: 3em;">
          <p>
            Nous travaillons avec du matériel professionnel connu et reconnu pour sa fiabilité et son efficacité.
          </p>
        </div>

        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/reddigitalcinema.svg" title="RED Digital Cinema" alt="TheCompagnie RED Digital Cinema" width="100%">
        </div>
        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/dji.svg" title="DJI" alt="TheCompagnie DJI" width="100%">
        </div>
        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/lumix.svg" title="Lumix" alt="TheCompagnie Lumix" width="100%">
        </div>
        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/gopro.svg" title="GoPro" alt="TheCompagnie GoPro" width="100%">
        </div>
        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/tbs.svg" title="T-Motor" alt="TheCompagnie T-Motor" width="100%">
        </div>
        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/betaflight.svg" title="BetaFlight" alt="TheCompagnie BetaFlight" width="100%">
        </div>
        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/frsky.svg" title="FrSKY" alt="TheCompagnie FrSKY" width="100%">
        </div>
        <div class="column has-text-left is-3-mobile is-3-tablet">
          <img src="@/assets/truerccanada.svg" title="TrueRC Canada" alt="TheCompagnie TrueRC Canada" width="100%">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Services',
  components: {}
}
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
  .technos {
    img {
      padding-right: 1em !important;
      padding-left: 1em !important;
    }
  }
}

.technos {
  img {
    opacity: 0.4;
    min-height: 3em;
    max-height: 4em;
    padding-right: 3em;
    padding-left: 3em;
  }
}
</style>
