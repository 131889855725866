<template>
  <nav class="navbar is-fixed-top">
    <div class="navbar-brand">
      <router-link tag="a" class="navbar-item" :to="detectlang + '/'">
        <img src="@/assets/logo_noir.svg" alt="TheCompagnie | FPV | DRONE | MÉDIA" width="165">
      </router-link>
      <div class="navbar-burger burger" data-target="navbarExampleTransparentExample" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <div id="navbarExampleTransparentExample" class="navbar-menu" :class="{'is-active': toggleburger}">
      <div class="navbar-end">
        <router-link tag="a" class="navbar-item" :to="detectlang + '/drone'" @click.native="closeMenu">
          <span v-if="detectlang == '/fr'">Drone FPV</span>
          <span v-if="detectlang == '/en'">FPV drone</span>
          <span v-if="detectlang == '/es'">Drone FPV</span>
        </router-link>
        <router-link tag="a" class="navbar-item" :to="detectlang + '/dronecinema'" @click.native="closeMenu">
          <span v-if="detectlang == '/fr'">FPV cinéma</span>
          <span v-if="detectlang == '/en'">Cinema FPV</span>
          <span v-if="detectlang == '/es'">FPV de cine</span>
        </router-link>
        <router-link tag="a" class="navbar-item" :to="detectlang + '/fpvlive'" @click.native="closeMenu">
          <span v-if="detectlang == '/fr'">FPV live</span>
          <span v-if="detectlang == '/en'">Live FPV</span>
          <span v-if="detectlang == '/es'">FPV en vivo</span>
        </router-link>
        <router-link tag="a" class="navbar-item" :to="detectlang + '/equipe'" @click.native="closeMenu">
          <span v-if="detectlang == '/fr'">Équipe</span>
          <span v-if="detectlang == '/en'">Team</span>
          <span v-if="detectlang == '/es'">Equipo</span>
        </router-link>
        <router-link tag="a" class="navbar-item" :to="detectlang + '/services'" @click.native="closeMenu">
          <span v-if="detectlang == '/fr'">Services</span>
          <span v-if="detectlang == '/en'">Services</span>
          <span v-if="detectlang == '/es'">Servicios</span>
        </router-link>
        <router-link tag="a" class="navbar-item" :to="detectlang + '/contact'" @click.native="closeMenu">
          <span v-if="detectlang == '/fr'">Contact</span>
          <span v-if="detectlang == '/en'">Contact us</span>
          <span v-if="detectlang == '/es'">Contactar</span>
        </router-link>
        <router-link
          v-for="lang in availableLanguages"
          :key="lang.code"
          tag="a"
          class="navbar-item lang"
          :to="changeLang(lang.code)"
          @click.native="closeMenu"
        >
          <img :src="`/${lang.icon}`" :alt="lang.alt" style="height:15px;">
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      toggleburger: false,
      languages: [
        { code: 'fr', icon: 'frlanguage.svg', alt: 'Language french' },
        { code: 'en', icon: 'enlanguage.svg', alt: 'Language english' },
        { code: 'es', icon: 'eslanguage.svg', alt: 'Language spanish' },
      ],
    };
  },
  computed: {
    detectlang() {
      const path = this.$route.path;
      if (path.startsWith('/fr')) return '/fr';
      if (path.startsWith('/en')) return '/en';
      if (path.startsWith('/es')) return '/es';
      return '/fr'; // Langue par défaut si aucune correspondance trouvée
    },
    availableLanguages() {
      // Filtre les langues pour exclure la langue actuellement utilisée
      return this.languages.filter(lang => this.detectlang !== `/${lang.code}`);
    },
  },
  methods: {
    changeLang(newLang) {
      const currentPath = this.$route.path;
      if (currentPath === '/' || currentPath === '/fr' || currentPath === '/en' || currentPath === '/es') {
        // Si l'utilisateur est sur la page d'accueil, redirige simplement vers la nouvelle langue
        return `/${newLang}/`;
      }
      // Sinon, remplace la langue actuelle par la nouvelle dans l'URL
      return currentPath.replace(/^\/(fr|en|es)/, `/${newLang}`);
    },
    toggleMenu() {
      this.toggleburger = !this.toggleburger;
    },
    closeMenu() {
      this.toggleburger = false;
    },
  },
};
</script>

<style lang='scss'>
.navbar {
  padding-top: 3em;
  padding-bottom: 1em;
  padding-left: 3em;
  padding-right: 3em;
}

.navbar-brand a.navbar-item {
  transition: 0.7s;
  &:hover {
    opacity: 0.7;
  }
}

.navbar-item.lang {
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.navbar-end a.navbar-item {
  transition: 0.7s;
  background-color: transparent !important;
  border: solid 1px transparent;
  padding-top: 0;
  padding-bottom: 0;
  &:hover {
    background-color: transparent !important;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
  }
}
</style>
