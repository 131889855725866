<template>
  <div class="container is-fullhd">
    <section class="hero is-black is-large has-bg-img9 pagebg">
      <div class="hero-body">
        <div class="svganim">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="600.000000pt" height="600.000000pt" viewBox="0 0 600.000000 600.000000"
           preserveAspectRatio="xMidYMid meet">
          <metadata>
          Created by potrace 1.11, written by Peter Selinger 2001-2013
          </metadata>
          <g transform="translate(490.000000,190.000000) rotate(180) scale(4.500,-4.5000)"
          fill="#000000" stroke="none">
          <path class="path" fill="transparent" stroke="#FFFFFF" stroke-width="0.16" stroke-miterlimit="1" d="M55.6,17.5C52.1,9.7,44.3,4.2,35.3,4.2c-12.2,0-22.1,9.9-22.1,22.1c0,9.6,6.2,17.8,14.7,20.8V56L8,41.7
          	c-0.6-0.4-1.3-0.7-2-0.7c-2.1-0.1-3.8,1.6-3.8,3.7v36.9c0,3,3.4,4.8,5.9,3l20-14.3v11.3c0,2,1.6,3.7,3.7,3.7h47.9
          	c2,0,3.7-1.7,3.7-3.7V47.1C91.8,44.1,98,35.9,98,26.3C98,14.1,88,4.2,75.8,4.2C66.8,4.2,59,9.7,55.6,17.5z M83.2,26.3
          	c0,4.1-3.3,7.4-7.4,7.4s-7.4-3.3-7.4-7.4s3.3-7.4,7.4-7.4S83.2,22.2,83.2,26.3z M42.7,26.3c0,4.1-3.3,7.4-7.4,7.4s-7.4-3.3-7.4-7.4
          	s3.3-7.4,7.4-7.4S42.7,22.2,42.7,26.3z"/>
          </g>
          </svg>
        </div>
        <div class="svgnoanim">
          <img src="@/assets/cinemadrone.svg" alt="TheCompagnie | FPV | DRONE | MÉDIA" style="opacity:1 !important;">
        </div>
        <div class="container has-text-left">
          <h1 class="title">
            FPV CINÉMA
          </h1>
          <h2 class="subtitle">
             El dron FPV en formato cinematográfico
          </h2>
        </div>
      </div>
    </section>
    <div class="container" style="padding-top: 4em;">
      <div class="columns is-multiline">
        <div class="column is-12 has-text-justified" style="padding-right: 3em; padding-left: 3em; margin-bottom: 3em;">
          <p>
            <strong>La inmersión y la innovación del FPV</strong> en un <strong>formato profesional</strong> y adaptado al <strong>cinema</strong>. Para <strong>todo tipo de producciones y medios</strong>, spot de televisión, serie, película, etc.
            Además de nuestros servicios de <strong>drone FPV</strong> convencionales, ofrecemos el formato cinematográfico para sus producciones. Nuestras máquinas son capaces de transportar una amplia gama de <strong>cámaras de cine</strong>.
          </p>
          <br>
          <p>
            Nuestros pilotos están <strong>formados y entrenados</strong> para realizar vuelos en inmersión (FPV) y producir <strong>imágenes dinámicas en formato cinematográfico</strong>.
          </p>
        </div>
        <div class="column is-7 has-text-left" style="padding-right: 1em; padding-left: 3em; margin-bottom: 3em;">
          <h3 class="subtitle">
            Nos cinécams
          </h3>
          <p>
            Ofrecemos cámaras de cine adaptadas para vuelo FPV y producción de video de alta calidad:
          </p><br>
          <div class="columns">
            <div class="column is-12">
              <b>RED Digital KOMODO 6K</b>
              <ul>
                  <li>Sensor Super 35mm Global Shutter CMOS 19.9MP</li>
                  <li>Resolución máxima 6K a 40ips, 4K a 60ips y 2K a 120ips</li>
                  <li>Formato REDCODE RAW & ProRes</li>
                  <li>Objetivo Laowa 9mm T2.9 Zero-D</li>
                </ul>
              </div>
            </div>
        </div>
        <div class="column is-5 has-text-left has-bg-img11" style="margin-bottom: 3em; min-height: 20em;">
        </div>
        <div class="column is-5 has-text-left has-bg-img12" style="min-height: 20em;">
        </div>
        <div class="column is-7 has-text-left" style="padding-right: 3em; padding-left: 1em; margin-bottom: 3em;">
          <div class="columns">
            <div class="column is-12">
              <b>PANASONIC LUMIX DC-BGH1</b>
              <ul>
                <li>Sensor Micro 4/3 Live MOS 10.2MP Dual Native ISO</li>
                <li>Resolución máxima C4K a 60ips, 4K a 60ips y FHD a 240ips</li>
                <li>Formato HEVC/H.265</li>
                <li>Objetivo Laowa 7.5mm T2.0 C-Dreamer</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="column is-12 has-text-center">
          <p>
            <h4 class="subtitle">Para más información o cualquier solicitud de servicio:</h4><br/>
            <b-button
              size="is-medium"
              icon-left="envelope"
              @click="mailtocontact"
              style="border: 1px solid black;"
              outlined>
              Contáctenos
            </b-button>
          </p>
        </div>
      </div>
    </div>
    <div class="container" style="margin-top: 3em;">
      <div class="columns is-mobile is-multiline technos">
        <div class="column is-12 has-text-center" style="padding-right: 3em; padding-left: 3em; margin-bottom: 3em;">
          <p>
            Nos confiaron sus realizaciones:
          </p>
        </div>
        <div class="column has-text-center is-6-mobile is-4-tablet">
          <img src="@/assets/universal.svg" title="Universal" alt="TheCompagnie Universal" width="100%">
        </div>
        <div class="column has-text-center is-6-mobile is-4-tablet">
          <img src="@/assets/netflix.svg" title="Netflix" alt="TheCompagnie Netflix" width="100%">
        </div>
        <div class="column has-text-center is-6-mobile is-4-tablet">
          <img src="@/assets/tf1.svg" title="TF1" alt="TheCompagnie TF1" width="100%">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Drone',
  methods: {
    mailtocontact() {
      window.location.href = "mailto:contact@thecompagnie.eu"
    }
  },
  components: {
  }
}
</script>
<style lang="scss">
  .video-container {
  	position: relative;
  	padding-bottom: 56.25%;
  	padding-top: 30px;
  	height: 0;
  	overflow: hidden;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  }
  @media screen and (max-width: 768px) {
    .technos{
      img{
        padding-right: 1em !important;
        padding-left: 1em !important;
      }
    }
  }
  .technos{
    img{
      opacity: 0.1;
      min-height: 3em;
      max-height: 4em;
      padding-right: 3em;
      padding-left: 3em;
    }
  }
</style>
