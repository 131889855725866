<template>
  <div class="container is-fullhd">
    <section class="hero is-black is-large has-bg-img5 pagebg">
      <div class="hero-body">
        <div class="svganim">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="600.000000pt" height="600.000000pt" viewBox="0 0 600.000000 600.000000"
           preserveAspectRatio="xMidYMid meet">
          <metadata>
          Created by potrace 1.11, written by Peter Selinger 2001-2013
          </metadata>
          <g transform="translate(0.000000,550.000000) scale(1.50000,-1.50000)"
          fill="#000000" stroke="none">
          <path class="path" fill="transparent" stroke="#ffffff" stroke-width="0.36" stroke-miterlimit="1" d="M151.2,165.4c-3.8,3.5-6.2,8.5-6.2,14c0,10.5,8.6,19.1,19.1,19.1c10.5,0,19.1-8.6,19.1-19.1c0-5.6-2.4-10.5-6.2-14
      			c12.6-5.1,21.5-17.4,21.5-31.8c0-2.1-1.7-3.8-3.8-3.8c-2.1,0-3.8,1.7-3.8,3.8c0,14.8-12,26.7-26.7,26.7c-14.8,0-26.7-12-26.7-26.7
      			c0-2.1-1.7-3.8-3.8-3.8c-2.1,0-3.8,1.7-3.8,3.8C129.7,147.9,138.6,160.3,151.2,165.4z M175.6,179.4c0,6.3-5.1,11.5-11.5,11.5
      			c-6.3,0-11.5-5.1-11.5-11.5c0-6.3,5.1-11.5,11.5-11.5C170.4,167.9,175.6,173.1,175.6,179.4z"/>
      		<path class="path" fill="transparent" stroke="#ffffff" stroke-width="0.36" stroke-miterlimit="1" d="M34.4,68.8c7.9,0,15.3-2.8,21.4-7.6l49.1,49.1c-13,14.3-21,33.1-21,53.9c0,20.8,8,39.6,21,53.9L55.7,267
      			c-6.1-4.9-13.5-7.6-21.4-7.6c-9.2,0-17.8,3.6-24.3,10.1C3.6,276,0,284.6,0,293.8c0,9.2,3.6,17.8,10.1,24.3
      			c6.5,6.5,15.1,10.1,24.3,10.1s17.8-3.6,24.3-10.1c6.5-6.5,10.1-15.1,10.1-24.3c0-7.9-2.8-15.3-7.6-21.3l49.1-49.1
      			c14.3,13,33.1,20.9,53.9,20.9s39.6-8,53.9-21l49.1,49.1c-4.9,6.1-7.6,13.5-7.6,21.3c0,9.2,3.6,17.8,10.1,24.3
      			c6.5,6.5,15.1,10.1,24.3,10.1c9.2,0,17.8-3.6,24.3-10.1c6.5-6.5,10.1-15.1,10.1-24.3s-3.6-17.8-10.1-24.3
      			c-6.5-6.5-15.1-10.1-24.3-10.1c-7.9,0-15.3,2.8-21.3,7.6L223.4,218c13-14.3,20.9-33.1,20.9-53.9s-8-39.6-21-53.9l49.1-49.1
      			c6.1,4.9,13.5,7.6,21.3,7.6c9.2,0,17.8-3.6,24.3-10.1c6.5-6.5,10.1-15.1,10.1-24.3s-3.6-17.8-10.1-24.3C311.6,3.6,303,0,293.8,0
      			S276,3.6,269.5,10.1c-6.5,6.5-10.1,15.1-10.1,24.3c0,7.9,2.8,15.3,7.6,21.4L218,104.8c-14.3-13-33.1-21-53.9-21
      			c-20.8,0-39.6,8-53.9,21L61.1,55.7c4.9-6.1,7.6-13.5,7.6-21.4c0-9.2-3.6-17.8-10.1-24.3C52.2,3.6,43.6,0,34.4,0
      			S16.6,3.6,10.1,10.1C3.6,16.6,0,25.2,0,34.4s3.6,17.8,10.1,24.3C16.6,65.2,25.2,68.8,34.4,68.8z M53.3,312.7
      			c-5.1,5.1-11.8,7.8-18.9,7.8s-13.9-2.8-18.9-7.8c-5.1-5.1-7.8-11.8-7.8-18.9c0-7.1,2.8-13.9,7.8-18.9c10.1-10.1,27.7-10.1,37.8,0
      			c5.1,5.1,7.8,11.8,7.8,18.9C61.1,300.9,58.4,307.7,53.3,312.7z M312.7,274.9c5.1,5.1,7.8,11.8,7.8,18.9c0,7.1-2.8,13.9-7.8,18.9
      			c-5.1,5.1-11.8,7.8-18.9,7.8c-7.1,0-13.9-2.8-18.9-7.8c-5.1-5.1-7.8-11.8-7.8-18.9c0-7.1,2.8-13.9,7.8-18.9
      			C285,264.8,302.6,264.8,312.7,274.9z M274.9,15.5c10.1-10.1,27.7-10.1,37.8,0c5.1,5.1,7.8,11.8,7.8,18.9s-2.8,13.9-7.8,18.9
      			c-5.1,5.1-11.8,7.8-18.9,7.8c-7.1,0-13.9-2.8-18.9-7.8s-7.8-11.8-7.8-18.9S269.8,20.5,274.9,15.5z M236.7,164.1
      			c0,40-32.6,72.6-72.6,72.6s-72.6-32.6-72.6-72.6s32.6-72.6,72.6-72.6S236.7,124.1,236.7,164.1z M15.5,15.5
      			c10.1-10.1,27.7-10.1,37.8,0c5.1,5.1,7.8,11.8,7.8,18.9s-2.8,13.9-7.8,18.9s-11.8,7.8-18.9,7.8s-13.9-2.8-18.9-7.8
      			S7.6,41.5,7.6,34.4S10.4,20.5,15.5,15.5z"/>
          </g>
          </svg>
        </div>
        <div class="svgnoanim">
          <img src="@/assets/team.svg" alt="Équipe de The Compagnie - Experts en drone FPV" style="opacity:1;">
        </div>
        <div class="container has-text-left">
          <h1 class="title">Experts</h1>
          <h2 class="subtitle">Passionnés</h2>
        </div>
      </div>
    </section>

    <div class="container" style="padding-top: 4em;">
      <div class="columns">
        <div class="column has-text-left" style="padding-right: 3em; padding-left: 3em;">
          <h3 class="subtitle">Expertise</h3>
          <p>
            TheCompagnie rassemble des talents de la création et de la communication pour vous offrir une expertise unique. Une équipe polyvalente inspirée par des voyages, des horizons divers, des rencontres et des projets uniques pour <b>une vision nouvelle sur vos idées</b>.<br><br>
            Nous travaillons avec <b>des outils modernes et des technologies puissantes</b> pour un développement rapide et optimisé de vos projets. Notre expertise en médias, aéronefs, communication et notre équipe multilingue offre <b>un travail rapide, soigné et innovant à la hauteur de votre entreprise</b>.
          </p>
        </div>
        <div class="column has-text-right" style="border-left: 1px solid black; padding-right: 3em; padding-left: 3em;">
          <h3 class="subtitle">Écoute</h3>
          <p>
            Nous avons mis en place une méthode et un positionnement face à votre demande qui règne sur l’écoute, la compréhension et le conseil. Français, anglais, espagnol, la première phase de travail est de <b>créer un dialogue privilégié</b> avec vous pour concevoir l’outil de communication le plus adapté à vos besoins.<br><br>
            Nous sommes fiers de <b>participer au développement de projets nouveaux</b> et TheCompagnie n’existe que par la réussite de ceux-ci.<br><br>
            Disponibles en tout temps, nous sommes toujours présents pour vous accompagner dans les moments importants de votre développement et de votre croissance.
          </p>
        </div>
      </div>
    </div>

    <div class="container" style="padding-top: 3em;">
      <div class="columns">
        <div class="column has-text-left" style="padding-right: 3em; padding-left: 3em;">
          <h3 class="subtitle">Technologie</h3>
          <p>
            Dans une recherche d'optimisation permanente, <b>notre équipe investit énormément sur la recherche et le développement</b> de nouvelles solutions pour vous offrir le meilleur.
            <b>Nous conceptualisons des solutions uniques dans le domaine du drone</b> et proposons des services de prises de vues aériennes grâce à des outils modernes ou des prototypes élaborés par nos soins.
          </p>
          <p><br><b>TheCompagnie est une entreprise de prestation drone FPV et média</b> qui rassemble une équipe de pilotes professionnels et d'experts en technologie de caméras volantes.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Equipe',
  components: {}
}
</script>

<script>

export default {
  name: 'Equipe',
  components: {
  }
}
</script>
