<template>
  <div class="container is-fullhd">
    <section class="hero is-white is-large">
      <div class="hero-body">
        <div class="svganim">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="600.000000pt" height="600.000000pt" viewBox="0 0 600.000000 600.000000"
           preserveAspectRatio="xMidYMid meet">
          <metadata>
          Created by potrace 1.11, written by Peter Selinger 2001-2013
          </metadata>
          <g transform="translate(100.000000,500.000000) scale(1.50000,-1.50000)"
          fill="#000000" stroke="none">
          <polygon class="path" fill="#FFFFFF" stroke="#000000" stroke-width="0.4" stroke-miterlimit="1" points="2.4,228 113.8,163.6 116.3,162.2 118.6,163.7 225.3,228 220.5,236.1 116.1,173.2 7.1,236.2 		"/>
          <path class="path" fill="#FFFFFF" stroke="#000000" stroke-width="0.4" stroke-miterlimit="1" d="M222.9,236.8H4.7H0v-4.7V115.2v-4.7h4.7h138.5l-2.2,2.2c-1.4,1.4-2.6,3-3.5,4.8c-0.4,0.8-0.8,1.7-1.1,2.5H9.5v107.4
    				h208.7V119.9h-4.4c-0.6-3.1-1.4-6.3-2.5-9.5h11.6h4.7v4.7v116.9v4.7C227.6,236.8,222.9,236.8,222.9,236.8z"/>
    			<path class="path" fill="#FFFFFF" stroke="#000000" stroke-width="0.4" stroke-miterlimit="1" d="M34,40.5c-0.3-2.1-0.1-4.1,0.6-6c0.7-1.9,1.7-3.6,3.2-5.1h0L61.2,6.3c0.8-0.8,1.6-1.6,2.6-2.3c0.9-0.7,1.9-1.3,3-1.8
    				c1.1-0.5,2.2-1,3.2-1.3c1.1-0.3,2.2-0.6,3.3-0.7l0.6-0.1l0,0c0,0,0,0,0,0c0.6-0.1,0.4,0,0.6,0c0.4,0,0.8-0.1,1.3-0.1
    				c0.7,0,1.2,0,1.4,0c26.1,0,55.5,16.9,80,39.8c27,25.3,48.2,58.1,52.3,83.6c0.3,1.6,0.5,3.1,0.6,4.5c0.1,1.4,0.2,2.7,0.2,3.8h0
    				v0.1h0c0,0.3,0,0.5,0,0.7c0,0.4,0,0.6,0,0.6c-0.1,1.3-0.1,2.1-0.1,2.4c0,0.3,0,0.6,0,0.8l-0.1,0.7c-0.2,1.1-0.4,2.2-0.7,3.3
    				c-0.3,1-0.7,2.1-1.3,3.2c-0.5,1.1-1.1,2.1-1.8,3c-0.6,0.9-1.4,1.7-2.1,2.4l0,0l-23.5,23.5c-1.2,1.2-2.6,2.2-4.1,2.8
    				c-1.6,0.7-3.2,1-4.9,1v0c-1.3,0-2.5-0.2-3.6-0.6c-1.2-0.4-2.2-0.9-3.3-1.7c-0.8-0.6-1.6-1.3-2.3-2.1c-0.7-0.7-1.3-1.5-1.9-2.5
    				l-0.2-0.3l-18.9-35.8l0,0c-0.8-1.5-1.3-3-1.6-4.6c-0.2-1.6-0.2-3.2,0.2-4.9c0.3-1.6,0.8-3,1.5-4.4c0.7-1.3,1.6-2.6,2.7-3.7l0,0
    				l8-8c-0.2-0.7-0.4-1.4-0.7-2.1c-0.4-1.2-1.1-2.5-1.8-4l0-0.1c-3.5-6.9-10.3-15.5-18.1-23.3c-7.7-7.7-16.3-14.6-23.4-18.2l0,0
    				c-1.3-0.7-2.4-1.2-3.3-1.5c-0.8-0.3-1.4-0.5-1.9-0.6l-0.9-0.2L93,67.5l-0.2,0.2c-1.5,1.3-3.1,2.3-4.9,3c-1.8,0.7-3.7,1-5.7,1
    				c-1.3,0-2.5-0.1-3.7-0.4c-1.2-0.3-2.3-0.7-3.3-1.2L74.8,70L40.7,49.8l-0.1-0.1c-1.8-1.1-3.2-2.3-4.3-3.8c-1.2-1.6-2-3.3-2.3-5.2
    				C34,40.8,34,40.5,34,40.5z M43.5,37.7c-0.1,0.4-0.2,0.9-0.2,1.4c0.1,0.5,0.3,0.9,0.6,1.3c0.4,0.5,0.9,1,1.6,1.4l0,0l34,20.1
    				l0.3,0.1c0.2,0.1,0.4,0.2,0.7,0.2c0.4,0.1,1,0.1,1.7,0.1c0.8,0,1.6-0.1,2.3-0.4c0.7-0.3,1.3-0.7,2-1.2l9.9-10.1l0,0
    				c0.5-0.5,1-0.8,1.6-1.2c0.4-0.2,0.9-0.4,1.4-0.6c0.5-0.2,0.8-0.3,1.1-0.3c0.6-0.1,1.1-0.2,1.5-0.2l0.9,0.1l2.2,0.4
    				c1,0.2,2.2,0.6,3.5,1.1c1.3,0.5,2.6,1.1,4.1,1.9v0c8.1,4.1,17.5,11.6,25.8,20c8.4,8.4,15.9,17.9,19.8,25.7l0,0
    				c0.9,1.7,1.6,3.4,2.3,5c0.6,1.7,1.1,3.3,1.4,4.8l0.1,0.9c0,0.5-0.1,1-0.2,1.5c-0.1,0.3-0.2,0.7-0.4,1.3c-0.2,0.6-0.4,1-0.6,1.4
    				c-0.3,0.6-0.7,1.1-1.1,1.6v0l-8.7,8.7h0c-0.4,0.4-0.8,0.9-1.1,1.4c-0.3,0.6-0.5,1.2-0.7,1.8c-0.1,0.6-0.1,1.1-0.1,1.6
    				c0.1,0.4,0.2,0.9,0.5,1.3l0.1,0.1l18.9,35.8c0.2,0.3,0.5,0.6,0.7,0.9c0.3,0.4,0.7,0.7,1,0.9c0.2,0.1,0.3,0.2,0.5,0.3
    				c0.2,0.1,0.5,0.1,0.7,0.1v0c0.4,0,0.8-0.1,1.1-0.2c0.4-0.2,0.8-0.5,1.2-0.9l23.5-23.5l0.2-0.2c0.3-0.3,0.7-0.7,1-1.1
    				c0.3-0.5,0.7-1,0.9-1.6c0.3-0.6,0.5-1.3,0.8-1.9c0.2-0.5,0.3-1,0.4-1.5l0-0.8c0-0.9,0.1-1.7,0.1-2.5l0-0.4c0-0.2,0-0.3,0-0.4h0
    				v-0.1h0c0-0.8-0.1-1.8-0.2-2.9c-0.1-1.2-0.3-2.4-0.5-3.9c-3.7-23.5-23.8-54.2-49.4-78.2c-22.9-21.4-50-37.2-73.6-37.2
    				c-0.7,0-1.1,0-1.1,0c-0.3,0-0.5,0-0.7,0c-0.8,0.1-0.5,0.1-0.8,0.1c-0.1,0-0.2,0-0.3,0c-0.5,0.1-1,0.2-1.5,0.4
    				c-0.7,0.2-1.3,0.5-1.9,0.8c-0.6,0.3-1.1,0.6-1.6,0.9c-0.4,0.3-0.8,0.6-1.1,1L68,12.9L44.5,36.2l0,0C44,36.7,43.7,37.2,43.5,37.7z
    				 M73.8,0.2L73.8,0.2C73.9,0.2,73.8,0.2,73.8,0.2C73.8,0.2,73.8,0.2,73.8,0.2z"/>
          </g>
          </svg>
        </div>
        <div class="svgnoanim">
          <img src="@/assets/contact.svg" alt="TheCompagnie | FPV | DRONE | MÉDIA" style="opacity:0.2;">
        </div>
        <div class="container has-text-left">
          <h1 class="title">
            Contact
          </h1>
          <h2 class="subtitle">
            Solutions
          </h2>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="columns">
        <div class="column has-text-right" style="padding-right: 3em; padding-left: 3em;">
          <p>We are here to listen to you, discuss your ideas and find the answers to your questions.</p>
          <h4 class="subtitle">
            Email
          </h4>
          <p><a href="mailto:contact@thecompagnie.eu" target="_blank">contact@thecompagnie.eu</a></p>
          <h4 class="subtitle">
            Phone
          </h4>
          <p>7/7 9am to 7pm | +33 (0)6 77 46 54 34</p>
          <h4 class="subtitle">
            Address
          </h4>
          <p>13 Clos des Bleuets 74940 Annecy | France</p>
          <h4 class="subtitle">
            Social networks
          </h4>
          <p style="padding-top:1em;">
            <a target="_blank" href="https://www.instagram.com/thecompagnie/">
              <img src="/instagram.svg" style="height:24px; margin-right: 1em;" alt="TheCompagnie | Instagram">
            </a>
            <a target="_blank" href="https://www.youtube.com/channel/UCbwOcqsQCYf8THFIbX3aqAw/">
              <img src="/youtube.svg" style="height:24px; margin-right: 1em;" alt="TheCompagnie | YouTube">
            </a>
            <a target="_blank" href="https://www.facebook.com/thecompagnie.eu/">
              <img src="/facebook.svg" style="height:24px;" alt="TheCompagnie | facebook">
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Contact',
}
</script>

<style lang="scss">
</style>
