<template>
  <div class="container is-fullhd">
    <section class="hero is-white is-large">
      <div class="hero-body">
        <div class="svganim">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="600.000000pt" height="600.000000pt" viewBox="0 0 600.000000 600.000000"
           preserveAspectRatio="xMidYMid meet">
          <metadata>
          Created by potrace 1.11, written by Peter Selinger 2001-2013
          </metadata>
          <g transform="translate(100.000000,500.000000) scale(0.90000,-0.90000)"
          fill="#000000" stroke="none">
          <path class="path" fill="transparent" stroke="#000000" stroke-width="1" stroke-miterlimit="10" d="M347.9,129.9l-144.3,66c-7,3.2-12.6,8.8-15.8,15.8l-66,144.3c-7.6,16.6,9.5,33.8,26.2,26.2l144.3-66
          	c7-3.2,12.6-8.8,15.8-15.8l66-144.3C381.7,139.4,364.6,122.3,347.9,129.9L347.9,129.9z M270.6,278.6c-12.5,12.5-32.7,12.5-45.2,0
          	s-12.5-32.7,0-45.2s32.7-12.5,45.2,0S283.1,266.1,270.6,278.6z M248,8C111,8,0,119,0,256s111,248,248,248s248-111,248-248
          	S385,8,248,8z M248,456c-110.3,0-200-89.7-200-200S137.7,56,248,56s200,89.7,200,200S358.3,456,248,456z"/>
          </g>
          </svg>
        </div>
        <div class="svgnoanim">
          <img src="@/assets/compass.svg" alt="TheCompagnie | FPV | DRONE | MÉDIA" style="opacity:0.2;">
        </div>
        <div class="container has-text-left">
          <h1 style="font-size: 3em;">
            <span style="font-family: Montserrat-Thin, AvertaStd-Extrathin, AvertaStd-Regular;">404</span><span style="font-family: Montserrat-Bold, 'AvertaStd-Bold', 'AvertaStd-Regular';">Compagnie</span>
          </h1>
          <h2 class="subtitle smallline">
            Error | Erreur | Error
          </h2>
          <router-link tag="a" to="/">
            <button class="button is-white is-inverted is-outlined">
              <span class="icon is-medium">
                <i class="fa fa-compass"></i>
              </span>
              <span>Return | Retour | Regreso</span>
            </button>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'Accueil',
  components: {
  }
}
</script>
<style lang='scss'>
  h2.subtitle.smallline{
    padding-left: 0.2em;
  }
</style>
