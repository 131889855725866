import Vue from 'vue';
import Router from 'vue-router';
import Notfound from '@/components/pages/Notfound';
import Accueil from '@/components/pages/fr/Accueil';
import Equipe from '@/components/pages/fr/Equipe';
import Live from '@/components/pages/fr/Live';
import Drone from '@/components/pages/fr/Drone';
import Dronecinema from '@/components/pages/fr/Dronecinema';
import Services from '@/components/pages/fr/Services';
import Projets from '@/components/pages/fr/Projets';
import Contact from '@/components/pages/fr/Contact';
import Discover from '@/components/pages/fr/Discover';

import Accueilen from '@/components/pages/en/Accueil';
import Equipeen from '@/components/pages/en/Equipe';
import Liveen from '@/components/pages/en/Live';
import Droneen from '@/components/pages/en/Drone';
import Dronecinemaen from '@/components/pages/en/Dronecinema';
import Servicesen from '@/components/pages/en/Services';
import Contacten from '@/components/pages/en/Contact';
import Discoveren from '@/components/pages/en/Discover';

import Accueiles from '@/components/pages/es/Accueil';
import Equipees from '@/components/pages/es/Equipe';
import Livees from '@/components/pages/es/Live';
import Dronees from '@/components/pages/es/Drone';
import Dronecinemaes from '@/components/pages/es/Dronecinema';
import Serviceses from '@/components/pages/es/Services';
import Contactes from '@/components/pages/es/Contact';
import Discoveres from '@/components/pages/es/Discover';

Vue.use(Router);

const router = new Router({
  mode: 'history', // Passe en mode history
  routes: [
    { path: '*', name: 'Notfound', component: Notfound },
    { path: '/', name: 'Accueil', component: Accueil, alias: '/fr/' },
    { path: '/equipe', name: 'Équipe', component: Equipe, alias: '/fr/equipe' },
    { path: '/fpvlive', name: 'Live', component: Live, alias: '/fr/fpvlive' },
    { path: '/drone', name: 'Drone', component: Drone, alias: '/fr/drone' },
    { path: '/dronecinema', name: 'Dronecinema', component: Dronecinema, alias: '/fr/dronecinema' },
    { path: '/fpv', name: 'Dronefpv', component: Drone, alias: '/fr/fpv' },
    { path: '/services', name: 'Services', component: Services, alias: '/fr/services' },
    { path: '/projets', name: 'Projets', component: Projets, alias: '/fr/projets' },
    { path: '/contact', name: 'Contact', component: Contact, alias: '/fr/contact' },
    { path: '/discover', name: 'Discover', component: Discover, alias: '/fr/discover' },
    { path: '/en/', name: 'Accueilen', component: Accueilen },
    { path: '/en/equipe', name: 'Équipeen', component: Equipeen },
    { path: '/en/fpvlive', name: 'Liveen', component: Liveen },
    { path: '/en/drone', name: 'Droneen', component: Droneen },
    { path: '/en/dronecinema', name: 'Dronecinemaen', component: Dronecinemaen },
    { path: '/en/fpv', name: 'Dronefpven', component: Droneen },
    { path: '/en/services', name: 'Servicesen', component: Servicesen },
    { path: '/en/contact', name: 'Contacten', component: Contacten },
    { path: '/en/discover', name: 'Discoveren', component: Discoveren },
    { path: '/es/', name: 'Accueiles', component: Accueiles },
    { path: '/es/equipe', name: 'Équipees', component: Equipees },
    { path: '/es/fpvlive', name: 'Livees', component: Livees },
    { path: '/es/drone', name: 'Dronees', component: Dronees },
    { path: '/es/dronecinema', name: 'Dronecinemaes', component: Dronecinemaes },
    { path: '/es/fpv', name: 'Dronefpves', component: Dronees },
    { path: '/es/services', name: 'Serviceses', component: Serviceses },
    { path: '/es/contact', name: 'Contactes', component: Contactes },
    { path: '/es/discover', name: 'Discoveres', component: Discoveres }
  ],
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ x: 0, y: 0 });
        }
      }, 500);
    });
  }
});

// Hook pour rediriger les anciennes routes
router.beforeEach((to, from, next) => {
  if (to.fullPath.includes('#')) {
    const newPath = to.fullPath.replace('#/', '/');
    next(newPath);
  } else {
    next();
  }
});

export default router;
