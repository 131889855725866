<template>
  <div class="container is-fullhd">
    <section class="hero is-black is-large has-bg-img10 pagebg">
      <div class="hero-body">
        <div class="svganim">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="600.000000pt" height="600.000000pt" viewBox="0 0 600.000000 600.000000"
           preserveAspectRatio="xMidYMid meet">
          <metadata>
          Created by potrace 1.11, written by Peter Selinger 2001-2013
          </metadata>
          <g transform="translate(490.000000,190.000000) rotate(180) scale(4.500,-4.5000)"
          fill="#000000" stroke="none">
          <path class="path" fill="transparent" stroke="#FFFFFF" stroke-width="0.16" stroke-miterlimit="1" d="M55.6,17.5C52.1,9.7,44.3,4.2,35.3,4.2c-12.2,0-22.1,9.9-22.1,22.1c0,9.6,6.2,17.8,14.7,20.8V56L8,41.7
          	c-0.6-0.4-1.3-0.7-2-0.7c-2.1-0.1-3.8,1.6-3.8,3.7v36.9c0,3,3.4,4.8,5.9,3l20-14.3v11.3c0,2,1.6,3.7,3.7,3.7h47.9
          	c2,0,3.7-1.7,3.7-3.7V47.1C91.8,44.1,98,35.9,98,26.3C98,14.1,88,4.2,75.8,4.2C66.8,4.2,59,9.7,55.6,17.5z M83.2,26.3
          	c0,4.1-3.3,7.4-7.4,7.4s-7.4-3.3-7.4-7.4s3.3-7.4,7.4-7.4S83.2,22.2,83.2,26.3z M42.7,26.3c0,4.1-3.3,7.4-7.4,7.4s-7.4-3.3-7.4-7.4
          	s3.3-7.4,7.4-7.4S42.7,22.2,42.7,26.3z"/>
          </g>
          </svg>
        </div>
        <div class="svgnoanim">
          <img src="@/assets/cinemadrone.svg" alt="TheCompagnie | We communicate, we develop" style="opacity:1 !important;">
        </div>
        <div class="container has-text-left">
          <h1 class="title">
            FPV CINEMA
          </h1>
          <h2 class="subtitle">
            FPV drone in cinema format
          </h2>
        </div>
      </div>
    </section>
    <div class="container" style="padding-top: 4em;">
      <div class="columns is-multiline">
        <div class="column is-12 has-text-justified" style="padding-right: 3em; padding-left: 3em; margin-bottom: 3em;">
          <p>
            <b>Immersion and innovation of FPV</b> in a <b>professional format</b> adapted to <b>cinema</b>. For <b>all types of productions and media</b>, TV spots, series, films, etc. In addition to our traditional <b>FPV drone</b> services, we offer the cinema format for your productions. Our machines are capable of carrying a wide range of <b>cinema cameras</b>.
          </p>
          <br>
          <p>
            Our pilots are <b>trained and skilled</b> to perform FPV flights and produce <b>dynamic images in cinema format</b>.
          </p>
        </div>
        <div class="column is-7 has-text-left" style="padding-right: 1em; padding-left: 3em; margin-bottom: 3em;">
          <h3 class="subtitle">
            Our cinecams
          </h3>
          <p>
            We offer cinema cameras suitable for FPV flight and high-end video production:
          </p><br>
          <div class="columns">
            <div class="column is-12">
              <b>RED Digital KOMODO 6K</b>
              <ul>
      						<li>Super 35mm Global Shutter CMOS 19.9MP Sensor</li>
      						<li>Maximum resolution 6K at 40fps, 4K at 60fps, and 2K at 120fps</li>
                  <li>REDCODE RAW & ProRes format</li>
      						<li>Laowa 9mm T2.9 Zero-D Lens</li>
      					</ul>
              </div>
            </div>
        </div>
        <div class="column is-5 has-text-left has-bg-img11" style="margin-bottom: 3em; min-height: 20em;">
        </div>
        <div class="column is-5 has-text-left has-bg-img12" style="min-height: 20em;">
        </div>
        <div class="column is-7 has-text-left" style="padding-right: 3em; padding-left: 1em; margin-bottom: 3em;">
          <div class="columns">
            <div class="column is-12">
              <b>PANASONIC LUMIX DC-BGH1</b>
              <ul>
                <li>Micro 4/3 Live MOS 10.2MP Dual Native ISO Sensor</li>
                <li>Maximum resolution C4K at 60fps, 4K at 60fps, and FHD at 240fps</li>
                <li>Format HEVC/H.265</li>
                <li>Laowa 7.5mm T2.0 C-Dreamer Lens</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="column is-12 has-text-center">
          <p>
            <h4 class="subtitle">For more information or any service request:</h4><br/>
            <b-button
              size="is-medium"
              icon-left="envelope"
              @click="mailtocontact"
              style="border: 1px solid black;"
              outlined>
              Contact us
            </b-button>
          </p>
        </div>
      </div>
    </div>
    <div class="container" style="margin-top: 3em;">
      <div class="columns is-mobile is-multiline technos">

        <div class="column is-12 has-text-center" style="display:none;">
          <p>
            As part of your theoretical drone pilot training, we recommend preparing on the site: <a href="https://www.drone-exam.fr" target="_blank">www.drone-exam.fr</a>
          </p>
        </div>
        <div class="column is-12 has-text-center" style="padding-right: 3em; padding-left: 3em; margin-bottom: 3em;">
          <p>
            They trusted us for their achievements:
          </p>
        </div>
        <div class="column has-text-center is-6-mobile is-4-tablet">
          <img src="@/assets/universal.svg" title="Universal" alt="TheCompagnie Universal" width="100%">
        </div>
        <div class="column has-text-center is-6-mobile is-4-tablet">
          <img src="@/assets/netflix.svg" title="Netflix" alt="TheCompagnie Netflix" width="100%">
        </div>
        <div class="column has-text-center is-6-mobile is-4-tablet">
          <img src="@/assets/tf1.svg" title="TF1" alt="TheCompagnie TF1" width="100%">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Drone',
  methods: {
    mailtocontact() {
      window.location.href = "mailto:contact@thecompagnie.eu"
    }
  },
  components: {
  }
}
</script>
<style lang="scss">
  .video-container {
  	position: relative;
  	padding-bottom: 56.25%;
  	padding-top: 30px;
  	height: 0;
  	overflow: hidden;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  }
  @media screen and (max-width: 768px) {
    .technos{
      img{
        padding-right: 1em !important;
        padding-left: 1em !important;
      }
    }
  }
  .technos{
    img{
      opacity: 0.1;
      min-height: 3em;
      max-height: 4em;
      padding-right: 3em;
      padding-left: 3em;
    }
  }
</style>
