<template>
  <div class="container is-fullhd">
    <header class="hero is-black is-large" style="position:relative;">
      <div class="hero-body" style="position: relative; overflow: hidden;">
        <video class="bg-video" src="/demo/showreelfpv.mp4" autoplay loop muted playsinline style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; z-index: 0;"></video>

        <div class="svganim">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="600.000000pt" height="600.000000pt" viewBox="0 0 600.000000 600.000000"
           preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,600.000000) scale(0.100000,-0.100000)"
          fill="#ffffff" stroke="none">
          <path class="path" fill="transparent" stroke="#ffffff" stroke-width="6" stroke-miterlimit="10" d="M2891 5710 c-96 -66 -203 -144 -233 -173 -7 -6 -46 -41 -86 -75 -131
          -116 -332 -328 -332 -352 0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -9 0 -5 -6 -16
          -13 -23 -6 -7 -30 -39 -51 -70 -22 -32 -43 -58 -48 -58 -4 0 -8 -5 -8 -10 0
          -6 -23 -45 -51 -88 -29 -42 -67 -104 -86 -137 -50 -88 -163 -322 -163 -337 0
          -8 -6 -20 -12 -26 -7 -7 -13 -16 -13 -20 0 -8 -21 -64 -36 -97 -8 -17 -52
          -158 -64 -205 -2 -8 -11 -39 -19 -67 -8 -29 -12 -53 -10 -53 3 0 0 -8 -5 -17
          -5 -10 -12 -34 -15 -53 -3 -19 -8 -46 -12 -60 -3 -14 -6 -32 -6 -40 0 -8 -4
          -35 -8 -60 -8 -42 -13 -88 -25 -217 -3 -29 -2 -53 1 -53 4 0 37 14 73 31 74
          36 248 109 271 114 8 2 47 15 85 29 39 15 79 28 90 31 11 2 70 18 132 34 62
          16 128 32 147 35 20 3 55 10 78 15 24 6 66 12 93 16 28 3 66 8 85 10 188 27
          564 27 760 0 19 -3 53 -7 75 -10 61 -8 106 -16 230 -44 128 -28 225 -55 340
          -95 158 -55 178 -63 296 -116 61 -27 113 -50 115 -50 7 0 -10 173 -26 262 -6
          31 -10 60 -9 65 1 4 0 15 -3 23 -3 8 -20 74 -37 145 -17 72 -33 137 -35 146
          -9 36 -78 236 -91 264 -7 17 -22 53 -33 80 -55 139 -246 483 -306 552 -14 16
          -28 35 -41 55 -26 41 -52 75 -70 94 -11 11 -33 38 -50 60 -91 118 -330 346
          -483 462 -13 9 -29 22 -35 27 -16 14 -213 145 -218 145 -2 0 -51 -32 -108 -70z"/>
          <path class="path" fill="transparent" stroke="#ffffff" stroke-width="6" stroke-miterlimit="10" d="M1239 2953 c-158 -112 -324 -261 -452 -404 -27 -30 -59 -65 -71 -79
          -69 -77 -230 -301 -276 -385 -6 -11 -22 -38 -35 -60 -119 -201 -260 -545 -311
          -760 -32 -136 -48 -217 -60 -300 -3 -22 -7 -51 -10 -65 -9 -53 -17 -168 -18
          -240 l-1 -75 111 -56 c157 -79 410 -177 498 -193 14 -3 29 -7 34 -10 9 -6 210
          -52 262 -60 19 -3 46 -8 60 -10 14 -3 48 -7 75 -11 28 -3 64 -8 80 -10 162
          -23 564 -18 700 9 11 2 45 7 75 11 30 4 64 9 75 11 11 2 34 7 50 10 17 3 77
          16 135 31 58 14 110 26 115 28 6 1 24 7 40 14 17 7 44 15 60 18 17 3 35 9 40
          13 6 4 46 19 90 35 44 15 84 32 90 36 5 5 14 9 21 9 7 0 49 18 94 39 74 35 80
          40 62 50 -11 6 -26 17 -34 24 -7 7 -35 28 -63 47 -238 161 -585 511 -747 754
          -23 34 -49 70 -59 81 -10 11 -18 24 -18 28 0 4 -16 31 -34 60 -44 69 -103 176
          -144 262 -19 39 -38 78 -44 88 -5 9 -23 52 -40 95 -17 42 -36 88 -42 102 -6
          14 -11 27 -12 30 -1 3 -20 63 -43 134 -23 71 -44 138 -47 150 -2 12 -11 48
          -19 82 -24 96 -53 253 -61 324 -12 111 -18 177 -19 193 0 9 -3 17 -7 17 -4 0
          -49 -30 -100 -67z"/>
          <path class="path" fill="transparent" stroke="#ffffff" stroke-width="6" stroke-miterlimit="10" d="M4651 2973 c-5 -84 -14 -158 -33 -268 -24 -139 -24 -139 -48 -235
          -12 -47 -25 -101 -30 -120 -5 -19 -21 -66 -35 -105 -14 -38 -25 -71 -25 -72 0
          -7 -6 -26 -15 -48 -4 -11 -21 -54 -37 -95 -69 -177 -269 -547 -340 -630 -10
          -11 -18 -24 -18 -29 0 -5 -3 -11 -7 -13 -5 -2 -20 -21 -35 -43 -15 -22 -39
          -53 -53 -70 -13 -16 -28 -35 -32 -40 -90 -121 -341 -372 -493 -493 -136 -109
          -123 -99 -182 -141 -27 -19 -48 -38 -48 -43 0 -5 12 -13 26 -18 14 -6 51 -22
          82 -36 199 -91 546 -197 721 -220 41 -5 87 -13 121 -19 140 -28 728 -24 800 5
          3 1 8 2 12 1 4 0 31 4 60 10 29 5 64 12 78 14 14 2 66 13 115 25 50 12 97 23
          105 25 140 34 388 128 564 215 l91 45 -1 75 c-1 113 -12 223 -36 355 -6 33
          -12 70 -13 82 -2 11 -6 30 -9 41 -4 11 -8 29 -10 39 -12 59 -36 152 -46 178
          -7 17 -14 39 -16 49 -5 26 -35 118 -43 131 -3 6 -6 17 -8 25 -1 8 -6 23 -11
          35 -6 11 -29 65 -52 120 -55 129 -163 333 -230 435 -172 262 -373 488 -615
          694 -62 53 -230 176 -241 176 -6 0 -12 -17 -13 -37z"/>
          </g>
          </svg>
        </div>
        <div class="svgnoanim">
          <img src="@/assets/logo_icon.svg" alt="TheCompagnie | FPV | DRONE | MÉDIA" style="opacity:0.2;">
        </div>
        <div class="container has-text-left">
          <h1 class="special">
            <span style="font-family: Montserrat-Thin, AvertaStd-Extrathin, AvertaStd-Regular;">The</span><span style="font-family: Montserrat-Bold, 'AvertaStd-Bold', 'AvertaStd-Regular';">Compagnie</span>
          </h1>
          <h2 class="subtitle smallline">
            FPV | DRONE | MÉDIA
          </h2>
        </div>
      </div>
    </header>

    <main>
      <!-- <section class="hero has-bg-img13 is-black is-large" @mouseover="playVideo('fpvlive')" @mouseleave="stopVideo('fpvlive')"> -->
      <section class="hero has-bg-img13 is-black is-large">
        <div class="hero-body">
        <!-- <video id="fpvlive" class="hover-video" src="/videos/fpvlive_1.mp4" loop muted></video> -->
          <div class="container has-text-left">
            <h1 class="title">
              Diffusion en direct FPV
            </h1>
            <h2 class="subtitle smallsub">
              Le cœur de l'action en direct
            </h2>
            <router-link tag="a" to="/fr/fpvlive">
              <button class="button is-black is-inverted is-outlined">
                <span class="icon is-medium">
                  <i class="fa fa-search"></i>
                </span>
                <span>Découvrir</span>
              </button>
            </router-link>
          </div>
        </div>
      </section>

      <!-- <section class="hero has-bg-img9 is-black is-large" @mouseover="playVideo('dronecinema')" @mouseleave="stopVideo('dronecinema')"> -->
      <section class="hero has-bg-img9 is-black is-large">
        <div class="hero-body">
        <!-- <video id="dronecinema" class="hover-video" src="/videos/dronecinema_1.mp4" loop muted></video> -->
          <div class="container has-text-left">
            <h1 class="title">
              FPV Caméra Cinéma
            </h1>
            <h2 class="subtitle smallsub">
              Le drone FPV au format cinéma
            </h2>
            <router-link tag="a" to="/fr/dronecinema">
              <button class="button is-black is-inverted is-outlined">
                <span class="icon is-medium">
                  <i class="fa fa-search"></i>
                </span>
                <span>Découvrir</span>
              </button>
            </router-link>
          </div>
        </div>
      </section>

      <!-- <section class="hero has-bg-img2 is-black is-large" @mouseover="playVideo('sportsextremes')" @mouseleave="stopVideo('sportsextremes')"> -->
      <section class="hero has-bg-img2 is-black is-large">
        <div class="hero-body">
          <!-- <video id="sportsextremes" class="hover-video" src="/videos/sportsextremes_1.mp4" loop muted></video> -->
          <div class="container has-text-left">
            <h1 class="title">
              Sports extrêmes
            </h1>
            <h2 class="subtitle smallsub">
              Point de vue unique en drone FPV
            </h2>
            <router-link tag="a" to="/fr/drone">
              <button class="button is-black is-inverted is-outlined">
                <span class="icon is-medium">
                  <i class="fa fa-search"></i>
                </span>
                <span>Découvrir</span>
              </button>
            </router-link>
          </div>
        </div>
      </section>

      <!-- <section class="hero has-bg-img1 is-black is-large" @mouseover="playVideo('poursuites')" @mouseleave="stopVideo('poursuites')"> -->
      <section class="hero has-bg-img1 is-black is-large">
        <div class="hero-body">
        <!-- <video id="poursuites" class="hover-video" src="/videos/poursuites_1.mp4" loop muted></video> -->
          <div class="container has-text-left">
            <h1 class="title">
              Poursuites de véhicules
            </h1>
            <h2 class="subtitle smallsub">
              Poursuites dynamiques et immersives en drone
            </h2>
            <router-link tag="a" to="/fr/drone">
              <button class="button is-black is-inverted is-outlined">
                <span class="icon is-medium">
                  <i class="fa fa-search"></i>
                </span>
                <span>Découvrir</span>
              </button>
            </router-link>
          </div>
        </div>
      </section>

      <section class="hero is-white is-medium">
        <div class="hero-body">
          <div class="container has-text-center">
            <h1 class="title">
              Ils nous font confiance
            </h1>
            <h2 class="subtitle smallsub">
              Pourquoi pas vous ?
            </h2>
            <div class="columns is-mobile is-multiline technos is-centered" style="margin-top:1em;">
              <div class="column has-text-center is-6-mobile is-4-tablet">
                <img src="@/assets/universal.svg" title="Universal" alt="TheCompagnie Universal" width="100%">
              </div>
              <div class="column has-text-center is-6-mobile is-4-tablet">
                <img src="@/assets/netflix.svg" title="Netflix" alt="TheCompagnie Netflix" width="100%">
              </div>
              <div class="column has-text-center is-6-mobile is-4-tablet">
                <img src="@/assets/cerclemusic.svg" title="Cercle Music" alt="TheCompagnie Cercle Music" width="100%">
              </div>
              <div class="column has-text-center is-6-mobile is-4-tablet">
                <img src="@/assets/citroen.svg" title="Citroën" alt="TheCompagnie Citroën" width="100%">
              </div>
              <div class="column has-text-center is-6-mobile is-4-tablet">
                <img src="@/assets/freerideworldtour.svg" title="Freeride World Tour" alt="TheCompagnie Freeride World Tour" width="100%">
              </div>
              <div class="column has-text-center is-6-mobile is-4-tablet">
                <img src="@/assets/tf1.svg" title="TF1" alt="TheCompagnie TF1" width="100%">
              </div>
              <div class="column has-text-center is-6-mobile is-4-tablet">
                <img src="@/assets/groupeppk.svg" title="PPK" alt="TheCompagnie PPK" width="100%">
              </div>
              <div class="column has-text-center is-6-mobile is-4-tablet">
                <img src="@/assets/framatome.svg" title="Framatome" alt="TheCompagnie Framatome" width="100%">
              </div>
              <div class="column has-text-center is-6-mobile is-4-tablet">
                <img src="@/assets/veka.svg" title="Veka" alt="TheCompagnie Veka" width="100%">
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: 'Accueil',
  methods: {
    playVideo(videoId) {
      const video = document.getElementById(videoId);
      if (video) {
        video.classList.add('visible'); // Rend la vidéo visible immédiatement
        setTimeout(() => {
          video.play(); // Démarre la vidéo après 400ms
        }, 400);
      }
    },
    stopVideo(videoId) {
      const video = document.getElementById(videoId);
      if (video) {
        video.classList.remove('visible'); // Cache la vidéo
        setTimeout(() => {
          video.pause(); // Arrête la vidéo après 600ms pour attendre la transition
        }, 600);
      }
    }
  }
}
</script>

<style lang='scss'>
.hero-body {
  position: relative;
}

.hover-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0; /* Par défaut, la vidéo est invisible */
  transition: opacity 0.6s ease-in-out; /* Transition douce pour l'opacité */
}

.hover-video.visible {
  opacity: 1; /* Devient visible lorsque la classe 'visible' est ajoutée */
}
</style>
