<template>
  <div class="container">
    <section class="hero is-black is-large has-bg-img13 pagebg">
      <div class="hero-body">
        <div class="svganim">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="600.000000pt" height="600.000000pt" viewBox="0 0 600.000000 600.000000"
           preserveAspectRatio="xMidYMid meet">
          <metadata>
          Created by potrace 1.11, written by Peter Selinger 2001-2013
          </metadata>
          <g transform="translate(605.000000,60.000000) rotate(180) scale(6.500,-6.5000)"
          fill="#000000" stroke="none">
          <path class="path" fill="transparent" stroke="#FFFFFF" stroke-width="0.16" stroke-miterlimit="1"
          d="M16.8,54l2.6,2.6l-5.1,5.1l-2.6-2.6c-15.6-15.6-15.6-41,0-56.6L14.3,0l5.1,5.1l-2.6,2.6C4.1,20.5,4.1,41.2,16.8,54L16.8,54z
 M68.3,2.6L65.7,0l-5.1,5.1l2.6,2.6c12.8,12.8,12.8,33.5,0,46.3l-2.6,2.6l5.1,5.1l2.6-2.6C83.9,43.5,83.9,18.2,68.3,2.6L68.3,2.6z
 M59.7,11.1l-2.6-2.6L52,13.7l2.6,2.6c3.9,3.9,6,9.1,6,14.6s-2.1,10.7-6,14.6L52,48l5.1,5.1l2.6-2.6c5.3-5.3,8.2-12.3,8.2-19.7
S65,16.4,59.7,11.1L59.7,11.1z M28,13.7l-5.1-5.1l-2.6,2.6c-5.3,5.3-8.2,12.3-8.2,19.7s2.9,14.4,8.2,19.7l2.6,2.6L28,48l-2.6-2.6
c-3.9-3.9-6-9.1-6-14.6s2.1-10.7,6-14.6L28,13.7L28,13.7z M50.9,30.9c0,6-4.9,10.9-10.9,10.9s-10.9-4.9-10.9-10.9S34,19.9,40,19.9
C46,19.9,50.9,24.8,50.9,30.9z M43.6,30.9c0-2-1.6-3.6-3.6-3.6s-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6S43.6,32.9,43.6,30.9z"/>
          </g>
          </svg>
        </div>
        <div class="svgnoanim">
          <img src="@/assets/broadcast2.svg" alt="TheCompagnie | FPV | DRONE | MÉDIA" style="opacity:0.2; max-height:8em;">
        </div>
        <div class="container has-text-left">
          <h1 class="title">Diffusion en direct FPV</h1>
          <h2 class="subtitle">Le cœur de l'action en direct</h2>
        </div>
      </div>
    </section>

    <!-- Section: Introduction -->
    <div class="container" style="padding-top: 4em;">
      <div class="columns is-multiline">
        <div class="column is-12 has-text-left" style="padding-right: 3em; padding-left: 3em; margin-top: 1em;">
          <h3 class="subtitle">Technologie innovante pour une immersion optimale</h3>
          <p>
            Découvrez notre <b>solution de diffusion en direct</b> en drone FPV, notre système <b>unique</b> permet d'offrir des prises de vue dynamiques et captivantes en temps réel. Idéale pour les <b>sports extrêmes, sports mécaniques, concerts, festivals</b>, notre technologie garantit une <b>immersion totale</b> lors de vos événements.
          </p>
          <br>
          <p>
            Grâce à notre <b>système de stabilisation gyrocam</b>, nous assurons une qualité d'<b>image exceptionnelle</b> tout en maintenant une <b>faible latence</b>, même dans des environnements complexes. Profitez d'une <b>diffusion en direct</b> qui capte l'attention de votre audience et transforme chaque événement en une <b>expérience mémorable</b>.
          </p>
          <hr>
        </div>

        <!-- Section: Comparaison Stabilisation -->
        <div class="column is-12 has-text-left" style="padding-right: 3em; padding-left: 3em; margin-top: 1em;">
          <h3 class="subtitle">Diffusion Haute Définition stabilisée avec gyrocamera</h3>
          <p>
            Les systèmes de diffusion sur drones FPV traditionnels présentent des limitations majeures : qualité d'image, poids excessif incompatible avec les petits drones, stabilisation entraînant une latence, ou encore des contraintes légales.
            <br><br>
            Notre solution surmonte ces défis en offrant une <b>stabilisation gyroscopique</b> de pointe, aussi appelée <b>gyrocamera</b>, permettant une <b>haute qualité d'image</b> et une <b>latence minimale</b>. Cette technologie, légère et compacte, permet d'obtenir des prises de <b>vue dynamiques et immersives</b>, idéales pour les événements en direct, tels que les sports extrêmes, les concerts ou les festivals.
          </p>
          <br>
          <div class="columns is-multiline" style="margin-top: 2em;">

          <!-- Column for second comparison slider -->
          <div class="column is-6">
            <div class="video-container" id="comparisonContainer2">
              <!-- Bottom video (SD - Diffusion FPV classique) -->
              <video id="videoBottom2" src="/demo/compareSD2_1.mp4" autoplay muted loop playsinline preload="auto"></video>

              <!-- Top video (HD - Notre diffusion) -->
              <div class="video-top" id="videoTop2">
                <video src="/demo/compareHD2_1.mp4" autoplay muted loop playsinline preload="auto"></video>
              </div>

              <!-- The slider for comparison -->
              <div class="slider" id="slider2">
                <div class="slider-icon" id="sliderIcon2">
                  <img src="@/assets/slider.svg" alt="Slider Icon for Video Comparison">
                </div>
              </div>

              <!-- Labels -->
              <div class="video-label video-label-left">Notre diffusion FPV</div>
              <div class="video-label video-label-right">Diffusion FPV classique</div>
            </div>
          </div>
          <!-- Column for first comparison slider -->
          <div class="column is-6">
            <div class="video-container" id="comparisonContainer">
              <!-- Bottom video (SD - Diffusion FPV classique) -->
              <video id="videoBottom" src="/demo/compareSD_1.mp4" autoplay muted loop playsinline preload="auto"></video>

              <!-- Top video (HD - Notre diffusion) -->
              <div class="video-top" id="videoTop">
                <video src="/demo/compareHD_1.mp4" autoplay muted loop playsinline preload="auto"></video>
              </div>

              <!-- The slider for comparison -->
              <div class="slider" id="slider">
                <div class="slider-icon" id="sliderIcon">
                  <img src="@/assets/slider.svg" alt="Slider Icon for Video Comparison">
                </div>
              </div>

              <!-- Labels -->
              <div class="video-label video-label-left">Notre diffusion FPV</div>
              <div class="video-label video-label-right">Diffusion FPV classique</div>
            </div>
          </div>
        </div>

          <p><small>Images capturées et diffusées en direct avec nos drones FPV.</small></p>
          <hr>
        </div>

        <!-- Section: Caractéristiques Techniques et Flexibilité -->
        <div class="column is-12" style="padding-right: 3em; padding-left: 3em; margin-top: 1em;">
          <h3 class="subtitle has-text-left">Caractéristiques techniques</h3>
          <div class="columns is-multiline">

            <!-- Résolution 1080p / 1080i à 25 ou 50 fps -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/resolution.svg" alt="1080p/i Resolution" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Diffusion en direct en 1080p ou 1080i</b><br>Une flexibilité de diffusion en <b>1080p (progressif) ou 1080i (entrelacé)</b> avec un choix de <b>25 à 50 images par seconde</b>. Cela permet d'ajuster la qualité de la diffusion en fonction des besoins de l'événement.
                  </p>
                </div>
              </div>
            </div>

            <!-- Focale 75° et grand angle 100° -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/focallength.svg" alt="Focale" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Choix de la focale</b><br>Une distance <b>focale 24 mm</b>, grand angle <b>équivalent à 15 mm</b>, ou même anamorphique, pour capturer des vues plus larges, des plans rapprochés ou donner un aspect cinéma selon les besoins de la réalisation.
                  </p>
                </div>
              </div>
            </div>

            <!-- Stabilisation 3 axes -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/stabilized.svg" alt="Stabilisation" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Stabilisation sur 3 axes</b><br>Une image sans vibrations type gyrocam, conservant l'effet <b>FPV</b>, et une stabilisation complète pour des plans à l'effet <b>"cable cam"</b>. L'option double opérateur permet un contrôle précis du cadrage, offrant ainsi une solution parfaite pour une réalisation unique.
                  </p>
                </div>
              </div>
            </div>

            <!-- Adaptabilité à différents types de drones -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/adaptivity.svg" alt="Adaptabilité" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Adaptabilité</b><br>S'adaptant à une grande variété de drones, des drones FPV traditionnels aux modèles plus petits pour les <b>espaces confinés</b>, et jusqu’aux drones <b>haute vitesse</b> pour capturer des scènes d’action à haute vitesse.
                  </p>
                </div>
              </div>
            </div>

            <!-- Latence faible -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/latency.svg" alt="Latence" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Faible latence</b><br>Notre technologie garantit une <b>faible latence</b>, idéale pour les événements en direct. Vos spectateurs vivront l’<b>action sans décalage</b>, en parfaite synchronisation avec l'événement réel.
                  </p>
                </div>
              </div>
            </div>

            <!-- Transmission longue portée -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/broadcast.svg" alt="Transmission" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Transmission longue portée</b><br>Même dans des environnements complexes, notre système assure une <b>transmission fiable</b> sur une distance allant jusqu’à 7 km. Que ce soit en ville ou dans de larges espaces, le <b>flux vidéo reste stable et de haute qualité</b>.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr>
        </div>

        <!-- Section: Enregistrement 4K à 100 fps -->
        <div class="column is-12 has-text-left" style="padding-right: 3em; padding-left: 3em;  margin-top: 1em;">
          <h3 class="subtitle">Enregistrement simultané pour la post-production</h3>
          <p class="has-text-justified" style="margin-bottom: 1em;">
            Notre système ne se contente pas seulement de la <b>diffusion en direct en 1080</b>. Il vous permet également de capturer chaque instant en <b>4K jusqu'à 100 images par seconde</b>, pour produire des ralentis fluides et détaillés. Idéal pour les récapitulatifs d’événements ou pour une <b>utilisation post-production</b>.
          </p>
          <div class="video-container" id="slowmoContainer">
            <!-- Video for slow motion comparison -->
            <video id="demoSlowmo" src="/demo/demoSlowmo_1.mp4" autoplay muted loop playsinline preload="auto"></video>

            <!-- Labels -->
            <div class="video-label video-label-left">Vitesse 1x en direct</div>
            <div class="video-label video-label-right">Vitesse 0.25x post-production</div>
          </div>
          <hr>
        </div>

        <!-- Section: Flexibilité et maîtrise technique -->
        <div class="column is-12 has-text-left" style="padding: 1em 3em; margin-bottom: 3em;">
          <h3 class="subtitle has-text-weight-bold">Flexibilité</h3>
          <p>
            Nos pilotes experts en drones FPV assurent la <b>sécurité</b> et la <b>conformité légale</b> lors de vos événements, qu'il s'agisse d'espaces urbains, de grandes scènes de festivals ou d'environnements naturels complexes. Nous adaptons nos interventions à chaque situation tout en respectant scrupuleusement les réglementations.
          </p>
          <br>
          <p>
            Notre technologie est conçue pour offrir une flexibilité totale. Que ce soit dans des espaces confinés, sur des petites scènes, ou dans des espaces vastes comme des montagnes, des forêts ou au-dessus de l'eau, nos drones garantissent une <b>stabilité optimale</b>, même en présence de vent, d'interférences radio ou en haute altitude. Vous bénéficiez ainsi d’une qualité d’image irréprochable, quelles que soient les conditions.
          </p>

          <!-- Icons for environments -->
          <div class="icons-environments" style="margin-top: 2em;">
            <div class="columns is-multiline is-centered is-mobile">
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/mountain.svg" alt="Montagne" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Haute altitude</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/city.svg" alt="Ville" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Environnement urbain</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/stages.svg" alt="Concert" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Concerts & Festivals</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/interferences.svg" alt="Complexité radio" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Environnement à interférences</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/wind.svg" alt="Vent fort" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Vent fort</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/forest.svg" alt="Petits espaces" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Petits espaces complexes</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/water.svg" alt="Au-dessus de l'eau" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Environnement marin</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Section: Vol en espaces confinés / intérieur -->
        <!-- <div class="column is-12 has-text-left" style="padding: 1em 3em; margin-bottom: 3em;">
          <h3 class="subtitle has-text-weight-bold">Vol en espaces confinés et en intérieur</h3>
          <p>
            Notre technologie FPV est spécialement conçue pour voler avec précision dans des <b>espaces confinés</b> ou en <b>intérieur</b>, ce qui la rend idéale pour les productions en <b>studio</b>, les <b>scènes</b>, ou tout environnement où l'espace est limité.
          </p>
          <br>
          <p>
            Grâce à la stabilisation avancée et à la maniabilité de nos drones, nous pouvons capturer des prises de vues uniques, même dans des endroits restreints, tout en garantissant la <b>sécurité</b> et la <b>précision</b> des plans. Nos drones offrent une <b>flexibilité totale</b> pour s'adapter à chaque environnement.
          </p>
          <br>
          <div class="video-container" id="indoorFlightDemo">
            <video id="demoIndoor" src="/demo/demoIndoor_1.mp4" autoplay muted loop playsinline preload="auto"></video>
          </div>
          <p><small>Vol en intérieur et en espace confiné et complexes.</small></p>
          <hr>
        </div> -->


        <!-- Call to Action Section -->
        <div class="column is-12 has-text-centered">
          <h4 class="subtitle">
            Planifiez une séance de tests pour en faire l'expérience par vous-même et transformer vos événements:
          </h4>
          <br>
          <button class="button is-black is-outlined is-medium" @click="mailtocontact">
            <span class="icon is-medium">
              <i class="fa fa-envelope"></i>
            </span>
            <span>Contactez-nous</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Live',
  methods: {
    mailtocontact() {
      window.location.href = "mailto:contact@thecompagnie.eu";
    },
    initSlider(containerId, videoTopId, sliderId) {
      const videoTop = document.getElementById(videoTopId);
      const slider = document.getElementById(sliderId);
      const comparisonContainer = document.getElementById(containerId);
      const labelLeft = comparisonContainer.querySelector('.video-label-left');
      const labelRight = comparisonContainer.querySelector('.video-label-right');
      let isDragging = false;

      // Function to update the slider position
      const updateSliderPosition = (x, rect, width) => {
        if (x < 0) x = 0;
        if (x > width) x = width;

        // Update the clip-path of the top video
        videoTop.style.clipPath = `inset(0px ${width - x}px 0px 0px)`;
        slider.style.left = x + 'px';

        // Update the opacity of labels based on slider position
        const threshold = 0.75 * width; // 75% of the width

        if (x < threshold) {
          // Reduce opacity of the left label as slider moves to the left beyond 75%
          const opacityLeft = Math.min(1, (x / threshold));
          labelLeft.style.opacity = opacityLeft;
        } else {
          labelLeft.style.opacity = 1; // Reset to full opacity if under threshold
        }

        if (x > (width - threshold)) {
          // Reduce opacity of the right label as slider moves to the right beyond 75%
          const opacityRight = Math.min(1, ((width - x) / threshold));
          labelRight.style.opacity = opacityRight;
        } else {
          labelRight.style.opacity = 1; // Reset to full opacity if under threshold
        }
      };

      // Set initial slider position (60% of the width)
      const setInitialSlider = () => {
        const rect = comparisonContainer.getBoundingClientRect();
        const width = rect.width;
        updateSliderPosition(0.60 * width, rect, width);
      };

      setInitialSlider();

      // Mouse and touch events for dragging
      const onMouseDown = () => { isDragging = true; };
      const onMouseUp = () => { isDragging = false; };

      const onMouseMove = (e) => {
        if (!isDragging) return;
        const rect = comparisonContainer.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const width = rect.width;
        updateSliderPosition(x, rect, width);
      };

      const onTouchMove = (e) => {
        if (!isDragging) return;
        const rect = comparisonContainer.getBoundingClientRect();
        const touch = e.touches[0];
        const x = touch.clientX - rect.left;
        const width = rect.width;
        updateSliderPosition(x, rect, width);
      };

      // Event listeners
      slider.addEventListener('mousedown', onMouseDown);
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('mousemove', onMouseMove);

      slider.addEventListener('touchstart', onMouseDown);
      document.addEventListener('touchend', onMouseUp);
      document.addEventListener('touchmove', onTouchMove);
    }
  },
  mounted() {
    // Initialize sliders
    this.initSlider('comparisonContainer', 'videoTop', 'slider');
    this.initSlider('comparisonContainer2', 'videoTop2', 'slider2');
  }
}

</script>

<style lang="scss" scoped>
.video-container {
  position: relative;
  max-width: 100%; /* Ensure the container does not exceed page width */
  height: auto;
  aspect-ratio: 16 / 9; /* Maintain 16:9 ratio for videos */
  margin: 0 auto;
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1; /* Ensure the video stays behind the top video */
}

/* Upper video styling */
.video-top {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; /* Make sure the video covers the entire container */
  z-index: 2;
  clip-path: inset(0px 25% 0px 0px); /* Set initial clip to 75% to the right */
}

/* Slider styling */
.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 75%; /* Set slider to start at 75% */
  width: 5px;
  background-color: #fff;
  cursor: ew-resize;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon in the center of the slider */
.slider-icon {
  position: absolute;
  top: 50%;
  width: 2.5em;
  height: 2.5em;
  transform: translateY(-50%);
  cursor: pointer; /* Makes the SVG clickable */
}

.video-label {
  position: absolute;
  font-size: 0.9em !important;
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  z-index: 4;
  pointer-events: none; /* Pour que le slider reste cliquable */
  user-select: none; /* Désactive la sélection du texte */
}

.slider-icon img {
  pointer-events: none;
  user-select: none; /* Désactive la sélection du logo du slider */
}

@media screen and (max-width: 768px){
.video-label {
  font-size: 0.5em !important;
}
}

.video-label-left {
  bottom: 10px;
  left: 10px;
}

.video-label-right {
  bottom: 10px;
  right: 10px;
}
</style>
